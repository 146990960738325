import RichardRyanImage from '../../assets/images/team/RichardRyan.png'
import csmithSECImage from '../../assets/images/team/csmithSEC.png'
import ShanaMcInnesImage from '../../assets/images/team/ShanaMcInnes.png'
import carsonturnerImage from '../../assets/images/team/carsonturner.png'
import ABigThingBadlyImage from '../../assets/images/team/ABigThingBadly.png'
import OxfoobarImage from '../../assets/images/team/Oxfoobar.png'
import OxKeefImage from '../../assets/images/team/0xKeef.png'
import KrunchXBTImage from '../../assets/images/team/KrunchXBT.png'
import OxPotamusImage from '../../assets/images/team/OxPotamus.png'
import ctrlshiftinImage from '../../assets/images/team/ctrlshiftin.png'
import isomorph13Image from '../../assets/images/team/isomorph13.png'
import bryanminearImage from '../../assets/images/team/bryanminear.png'
import incalstoryImage from '../../assets/images/team/incalstory.png'
import DanVillageArtImage from '../../assets/images/team/DanVillageArt.png'
import TedHyman247Image from '../../assets/images/team/TedHyman247.png'
import cesarperezfitImage from '../../assets/images/team/cesarperezfit.png'
import ShooterMcGavinImage from '../../assets/images/team/ShooterMcGavin.png'
import Blake_NashBRImage from '../../assets/images/team/Blake_NashBR.png'

export const WHITE_LIST_MINT_TIME = 2 * 3600
export const TOTAL_NFT_SUPPLY = 10000

export let FETCH_NFTS_URI = 'https://api2.ticketaura.lol/api/v2'
export let PROVIDER_URL = 'https://rpcnode.ticketaura.lol/'
export let supportedChainId = 1337
export let supportedChainName = 'Dave'
export let supportedChainCurrency = 'CPAY'
export let blockExplorerUrl = 'https://epirus.ticketaura.lol/'

// if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') {
//   PROVIDER_URL = 'https://goerli.infura.io/v3/be7a0c3ba4154a8aaa4774f31a286e16'
//   FETCH_NFTS_URI = 'https://deep-index.moralis.io/api/v2'
//   supportedChainId = 5
//   supportedChainName = 'Goerli Testnet'
//   supportedChainCurrency = 'ETH'
//   blockExplorerUrl = 'https://goerli.etherscan.io'
// }

export const team = [
  {
    name: 'RichardRyan',
    isAdvisor: false,
    image: RichardRyanImage,
  },
  {
    name: 'csmithSEC',
    isAdvisor: false,
    image: csmithSECImage,
  },
  {
    name: 'ShanaMcInnes',
    isAdvisor: false,
    image: ShanaMcInnesImage,
  },
  {
    name: 'carsonturner',
    isAdvisor: true,
    image: carsonturnerImage,
  },
  {
    name: 'ABigThingBadly',
    isAdvisor: true,
    image: ABigThingBadlyImage,
  },
  {
    name: '0xfoobar',
    isAdvisor: true,
    image: OxfoobarImage,
  },
  {
    name: '0xKeef',
    isAdvisor: false,
    image: OxKeefImage,
  },
  {
    name: 'KrunchXBT',
    isAdvisor: false,
    image: KrunchXBTImage,
  },
  {
    name: '0xPotamus',
    isAdvisor: false,
    image: OxPotamusImage,
  },
  {
    name: 'ctrlshiftin',
    isAdvisor: false,
    image: ctrlshiftinImage,
  },
  {
    name: 'isomorph13',
    isAdvisor: false,
    image: isomorph13Image,
  },
  {
    name: 'bryanminear',
    isAdvisor: false,
    image: bryanminearImage,
  },
  {
    name: 'incalstory',
    isAdvisor: false,
    image: incalstoryImage,
  },
  {
    name: 'DanVillageArt',
    isAdvisor: false,
    image: DanVillageArtImage,
  },
  {
    name: 'TedHyman247',
    isAdvisor: false,
    image: TedHyman247Image,
  },
  {
    name: 'cesarperezfit',
    isAdvisor: false,
    image: cesarperezfitImage,
  },
  {
    name: 'ShooterMcGavin_',
    isAdvisor: false,
    image: ShooterMcGavinImage,
  },
  {
    name: 'Blake_NashBR',
    isAdvisor: false,
    image: Blake_NashBRImage,
  },
]

export const FAQS_ARRAY = [
  {
    question: 'What is Ghostball?',
    answer:
      "Ghostball is a collection of up to 10,000 dynamic generative profile picture NFTs. It's also the umbrella project over what we intend to be an ambitious series of initiatives, tools and rewards.",
  },
  {
    question: 'What makes Ghostball different from other NFT pfp projects on Ethereum?',
    answer: `We set out to make simple but elegant art that will look good as a pfp on your favorite social media platform. We think we've done that.

    But the world doesn't need a copypasta pfp project. We didn't create Ghostball solely for you to flip JPEGs, although that can be fun.
    
    Think of our NFTs as an access pass for the tools and ideas we're going to create.
    
    Purchasing our NFT gets you beta access and also gives you a voice in which of our ideas resonate the most.
    
    But instead of a plain-looking file of a ticket, you're getting cool pfp art.
    
    Our NFTs will be dynamic. Depending on the NFT you mint, you will be able to change the color of a trait to match your favorite sports team. You'll even be able to change the metadata on chain to reflect your choice. We've baked some future dynamic fun into the code as well.
    
    Our NFTs are about fun and tech rather than flipping and !floor. We're good ghosts, for the most part. We don't intend to haunt your dreams, although we can be mischievous. We're focused on providing you with tools, incentives and rewards. We've already worked hard on providing further value in 2022, and we anticipate being able to add utility soon after launch. Hint: You might want to join a fantasy football league this year … We hear Yahoo! has good app for that.
    
    Our NFTs are collaborative. We gave away 1 ETH and 40 free commemorative NFTs to those who filled out an early survey. We're holding a social media contest pre-mint to allow our early fans to determine one of our 1/1s. We'll be surveying our community after launch, and we have some cool things planned for our active community members. We want to continue the ethos of involving you and your ideas.`,
  },
  {
    question: 'Who are you? And is your team doxxed?',
    answer: `We are a team of passionate, engaged NFT community members with a long-standing interest and participation in crypto and the NFT ecosystem.

    That starts with the founder of Blackbeard Development Group,@RichardRyan.
    
    Richard has been at the forefront of digital and social since 2005. He has innovated in the space working with fortune 500 companies on their creative and marketing strategies along with co-founding Black Rifle Coffee Company building out his own digital media brands with over 20 billion views and millions of followers.
    
    Richard's business success has enabled us to assemble a talented group.
    
    As a team, we're grateful to Richard for backing us with the resources we needed to bring Ghostball to life.
    
    Some of the Ghostball team members are doxxed. Some have attached their digital identities to the project. And others remain private.
    
    Here's a list of other Ghostball team members, excluding any agencies we've partnered with (Click on the links below to visit their twitter accounts):
    
    @csmithSEC
    @ShooterMcGavin_
    @bryanminear
    @incalstory
    @carsonturner (advisor)
    @ABigThingBadly (advisor)
    @0xfoobar (advisor)
    @0xKeef
    @KrunchXBT
    @0xPotamus
    @ctrlshiftin
    @Isomorph13
    @TedHyman247 (artist)
    @DanVillageArt (artist) `,
  },
  {
    question: 'Is Ghostball a sports-related project?',
    answer: `Yes. What gave it away?

    We think passion is a defining characteristic of the NFT community. Sports fans exhibit that same passion and enthusiasm. 
    
    No matter which ghost identity you take on, we just know you're going to add your own passion for NFTs and sports to our community.
    
    “Sports” is a wide-ranging category covering merch, game-day experiences, media, fandom, fantasy games and more. If you can dream it, we can help build it.
    
    Just want to rep your favorite team on game day? Our dynamic NFTs will let you change the color of specific traits on chain. Are you a fantasy football fanatic? We'll have something unique for you as well.`,
  },
  {
    question: 'Do you have a roadmap?',
    answer: `Yes and no. A roadmap can suggest an inflexible plan and a time limit with a beginning and an end. 

    We don't have an end in mind. But we do have some initial ideas that we've spent time working on behind the scenes.
    
    This is a journey. And if we told you everywhere we may go together, it would ruin the surprise.
    
    We have concrete plans and we also want to involve your ideas and feedback. We'll try to strike a balance between mystery and excitement.
    
    We'll release a creative roadmap image soon that will offer some hints.`,
  },
  {
    question: 'What is your vision for the project? What happens after the mint?',
    answer: `We don't want to hand the project off to you as community members and disappear. Nor do we want to be dictators.

    We have a tool that we've already developed that we'll hand over to the community not long after the mint. We're excited about it. (Hope you like blockchain + sports!) 
    
    We also have a few other small plans in place to delight and entertain you while we work on executing our next big ideas under the Ghostball umbrella.
    
    We want you to work with us to identify what's next. Perhaps you want to subsidize Little League jerseys for a community in need. Maybe we spin out a sports podcast. Maybe we create a machine learning algorithm that helps you optimize your fantasy draft. Maybe we start a pickleball league … or not. 👻
    
    We're excited to see what subcommunities and ideas form from within the community so we can partner with you to bring them to life.
    
    The bottom line: We care more about the passion of our community and providing things that you love than we do about !floor.`,
  },
  {
    question: 'When is the mint date?',
    answer: `Expect us to launch soon. We have not announced a firm date.

    We'll provide more concrete details. But our mint will be open for a specific time period.
    
    Ghostball will be minted in waves. NFTs minted in Wave 1 will give token holders the opportunity to participate in product testing and contribute to the roadmap.
    
    We may provide future benefits to early adopters for holding our early-generation mints.`,
  },
  {
    question: 'How much will it cost?',
    answer: `Minting a single Ghostball will cost a maximum of 0.1 ETH plus gas. We have not set a final mint price pending market conditions.`,
  },
  {
    question: 'Do I need a whitelist spot? If so, how do I get one?',
    answer: `Yes. In an effort to attract a strong holder base of real, active people, we are whitelisting our mint.

    In addition to giving away whitelist spots to NFT communities that we respect, we'll be offering opportunities to people who engage with us on social media (follow @ghostballNFT and join our Discord) and verify their wallets.
    
    That said, we don't intend to make you jump through many hoops to buy our NFT.`,
  },
  {
    question: "What's the lore around Ghostball?",
    answer: `Do you believe in ghosts? What about good vs. evil?

    We may not know your answer. But we know ghosts are perfect for sports. They're mysterious, connect us to past memories and add an element of superstition.
    
    Whether you want to haunt your rival team, become a legend that never dies (like the ghost of Babe Ruth in the movie “The Sandlot”) or defy the laws of physics, we'll do it together.
    
    We've also created a number of Ghostball personalities, which we'll reveal one our mint goes live. Each personality will have a different goal within the Ghostball community, so we'll see which one(s) make the biggest impact on the ecosystem.`,
  },
  {
    question: 'How will you create the Ghostballs?',
    answer: `We will algorithmically generate the Ghostballs by combining traits with varying rarities in different categories (or layers).`,
  },
  {
    question: 'What rights do I get as a Ghostball holder?',
    answer: `All Ghostball token holders get non-commercial individual use rights (example: print your Ghostball on a T-shirt).`,
  },
  {
    question: 'What kind of token are you using for your NFTs?',
    answer: `We're minting ERC-721 tokens on the Ethereum network. Our NFTs are composable and bundled. That's a fancy way of saying that we have undefined layers within our NFTs that we can incorporate in the future – or that you can use to modify your NFT on the blockchain.`,
  },
  {
    question: 'Do I need an Ethereum wallet to buy a Ghostball NFT?',
    answer: `Yes. You do need an Ethereum wallet in order to mint a Ghostball NFT and to eventually access token-gated portions of our website and Discord server.`,
  },
  {
    question: "I've made it this far. Will you at least tell me a ghost joke?",
    answer: `Certainly.

      Why do ghosts love elevators?

      …It lifts their spirits.`,
  },
]
