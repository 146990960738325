import styled from 'styled-components'
import { maxWidth, screenSizes } from '../../../styles/theme'
import starBG from '../../../assets/images/star-bg.svg'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #000;
  gap: 2em;
`

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 0 2em;
  max-width: ${maxWidth};
  @media (max-width: ${screenSizes.ML}px) {
    padding: 1em 10px 0em 10px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    padding: 1em 0em 2em 0em;
  }
  padding-top: 64px;
  *::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: ${(props: any) => props.theme.primary};
    border-radius: 20px;
    background-clip: content-box;
  }
`
export const TblContainer = styled.div`
  td {
    border: 1px solid white;
    color: white;
    padding: 0 5px;
    vertical-align: top;
    @media (max-width: ${screenSizes.XS}px) {
      p {
        font-size: 14px;
      }
    }
    @media (max-width: 400px) {
      p {
        font-size: 12px;
      }
    }
  }
`
export const CardBodyTextCntr = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 14px;
  max-width: 95vw;
  li::marker {
    color: white;
  }
  li {
    padding: 8px 0;
  }
`
