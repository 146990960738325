import Web3 from 'web3'
import { PROVIDER_URL } from '../shared/helpers/config'
import { web3 } from '../wallet_helpers/useAuth'
import { composableABI, composableAddress } from './abi/ComposableNFT'
import { whiteListABI, whiteListAddress } from './abi/whiteList'

export const ComposableNFT: any = new web3.eth.Contract(composableABI, composableAddress)
export const WhiteList: any = new web3.eth.Contract(whiteListABI, whiteListAddress)

const provider = new Web3(PROVIDER_URL)
// export const ComposableNFT: any = new provider.eth.Contract(composableABI, composableAddress)
// export const WhiteList: any = new provider.eth.Contract(whiteListABI, whiteListAddress)

export const ComposableNFTPublic = new provider.eth.Contract(composableABI, composableAddress)
export const WhiteListPublic = new provider.eth.Contract(whiteListABI, whiteListAddress)
