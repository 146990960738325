import styled, { ThemeProps } from 'styled-components'
import { maxWidth, screenSizes } from '../../styles/theme'
import starBG from '../../assets/images/star-bg.svg'

export const ContainerAbout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75px 0 200px 0;
  background: #000 url(${starBG});
`
export const ContainerRoadMap = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 0 0;
  background: #000 url(${starBG});
  flex-direction: column;
  gap: 80px;
  @media (max-width: ${screenSizes.M}px) {
    padding: 80px 0 0 0;
    gap: 40px;
  }
`

export const ContainerGuide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75px 0 200px 0;
  flex-direction: column;
  background: ${(props: ThemeProps<any>) => props.theme.primary};
`
export const ContainerTeam = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0 0px 0;
  flex-direction: column;
  background: ${(props: ThemeProps<any>) => props.theme.primary};
  gap: 80px;
`
export const ContainerFAQ = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0 175px 0;
  flex-direction: column;
  background: ${(props: ThemeProps<any>) => props.theme.primary};
  gap: 20px;
`
export const ContainerTail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background: #000 url(${starBG});
  @media (max-width: ${screenSizes.XS}px) {
    padding: 35px 15px;
  }
`
export const TailWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${maxWidth};
  @media (max-width: ${screenSizes.XS}px) {
    flex-direction: column;
    gap: 24px;
    p:first-child {
      text-align: center;
    }
  }
`
export const FaqAnswerCntr = styled.div`
  animation: slide-down-2 700ms forwards, fade-in-slow 500ms forwards;

  @keyframes fade-in-slow {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-down-2 {
    0% {
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`
export const IconsCntr = styled.div`
  display: flex;
  gap: 16px;
`
export const TeamCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 36px 16px;
  width: 100%;
  max-width: ${maxWidth};
  @media (max-width: ${screenSizes.XL}px) {
    width: 90%;
  }
  @media (max-width: ${screenSizes.M}px) {
    grid-column-gap: 0;
  }
`
export const FAQItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  div {
    display: flex;
    gap: 18px;
  }
  img:last-child {
    cursor: pointer;
  }
  @media (max-width: ${screenSizes.M}px) {
    p {
      max-width: 80%;
    }
  }
`
export const Pad = styled.div`
  padding: 15px 0;
`
export const TextWrap = styled.div`
  display: flex;
  padding: 5px 0;
`
export const AnchorTextInLine = styled.a`
  position: relative;
  display: inline-block;
  color: ${(props: any) => props.theme.black};
  font-size: 18px;
  font-style: italic;
  line-height: 24px;
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  :hover {
    color: ${(props: any) => props.theme.white};
  }
`
export const AnchorInLine = styled.a`
  position: relative;
  display: inline-block;
  color: ${(props: any) => props.theme.primary};
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  :hover {
    opacity: 0.7;
  }
`
export const AnchorTextBlock = styled.a`
  position: relative;
  display: inline-block;
  color: ${(props: any) => props.theme.black};
  font-size: 18px;
  font-style: italic;
  line-height: 24px;
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  :hover {
    color: ${(props: any) => props.theme.white};
  }
`
export const FAQContainer = styled.ul`
  padding-right: 18px;
  width: 100%;
  max-width: ${maxWidth};
  list-style-type: none;
`
export const FlexContainer = styled.div`
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: start;
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
  }
`

export const RoadMapHead = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  @media (max-width: ${screenSizes.ML}px) {
    max-width: 700px;
  }

  @media (max-width: ${screenSizes.M}px) {
    text-align: center;
    max-width: 600px;
  }
  @media (max-width: ${screenSizes.S}px) {
    text-align: center;
    max-width: 500px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    text-align: center;
    max-width: 400px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    text-align: center;
    max-width: 300px;
  }
`

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  flex-direction: column;
  max-width: 560px;
`
export const AboutBody = styled.div`
  display: flex;
  gap: 15px;
`
interface IAboutProps {
  zIndex?: string
  bg?: boolean
}
export const AboutLeft = styled.div<IAboutProps>`
  display: flex;
  z-index: ${(props: any) => props.zIndex};
  background: ${(props: any) => (props.bg ? props.theme.primary : '')};
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: start;
  max-width: 550px;
  position: relative;
  @media (max-width: ${screenSizes.M}px) {
    align-items: center;
    max-width: 350px;
  }
`
export const ProgressContainer = styled.div`
  background: ${(props: any) => props.theme.blackFaded};
  height: 100%;
  width: 3px;
  position: absolute;
  left: 25px;
`
interface IProgressBarProps {
  height?: Number
}
export const ProgressBar = styled.div<IProgressBarProps>`
  height: ${(props: any) => props.height + '%' || '0%'};
  width: 3px;
  background: black;
`
export const DetailsContainerAbout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: start;
  max-width: 550px;
  @media (max-width: ${screenSizes.M}px) {
    align-items: center;
    max-width: 350px;
  }
`

export const Avatar1 = styled.img`
  width: 590px;
  height: 520px;
  @media (max-width: ${screenSizes.ML}px) {
    width: 355px;
    height: 310px;
  }
`

export const Avatar2 = styled.img`
  width: 415px;
  height: 100%;
  @media (max-width: ${screenSizes.M}px) {
    width: 240px;
    height: 325px;
  }
`

export const FloatingTile = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 122px;
  height: 42px;
  border-radius: 50px;
  background: ${(props: ThemeProps<any>) => props.theme.fadedWhite};
  bottom: 20px;
  right: 15px;
  color: ${(props: ThemeProps<any>) => props.theme.white};
`

export const Card = styled.div`
  width: 280px;
  height: 320px;
  background: ${(props: ThemeProps<any>) => props.theme.fadedWhite};
  border-radius: 10px;
  @media (max-width: ${screenSizes.XL}px) {
    width: 260px;
  }
  @media (max-width: ${screenSizes.L}px) {
    width: 240px;
  }
  @media (max-width: ${screenSizes.ML}px) {
    width: 220px;
  }
`
interface ICardProps {
  marginTop?: string
}
export const CardsContainer = styled.div`
  margin-top: ${(props: ICardProps) => props.marginTop || '2em'};
  display: flex;
  gap: 140px;

  @media (max-width: ${screenSizes.XXL}px) {
    gap: 120px;
  }
  @media (max-width: ${screenSizes.XL}px) {
    gap: 50px;
  }
  @media (max-width: ${screenSizes.L}px) {
    gap: 40px;
  }
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
    gap: 48px;
  }
`
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`
export const TailTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const CornerBox = styled.div`
  height: 610px;
  width: 215px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(66.5px);
  background: transparent;
  border: 1px solid white;
  border-left: none;
  border-radius: 0 24px 24px 0;
  @media (max-width: ${screenSizes.XXL}px) {
    width: 160px;
  }
  @media (max-width: ${screenSizes.XL}px) {
    width: 120px;
  }
  @media (max-width: ${screenSizes.L}px) {
    width: 90px;
  }
  @media (max-width: ${screenSizes.ML}px) {
    width: 70px;
  }
`
export const LineRight = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props: ThemeProps<any>) => props.theme.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(66.5px);
  @media (max-width: ${screenSizes.XL}px) {
    width: 60%;
  }
  @media (max-width: ${screenSizes.M}px) {
    width: calc(50vw - 66.5px);
  }
`
export const LineLeft = styled.div`
  height: 1px;
  width: 100%;
  background: #ffffff;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translateX(-66.5px);
  -ms-transform: translateX(-66.5px);
  transform: translateX(-66.5px);
  @media (max-width: ${screenSizes.XL}px) {
    width: 50%;
  }
  @media (max-width: ${screenSizes.M}px) {
    width: calc(50vw - 66.5px);
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`

export const CardTextWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
  @media (max-width: ${screenSizes.L}px) {
    p:first-child {
      font-size: 30px;
      margin: 0;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin: 0;
    }
  }
  @media (max-width: ${screenSizes.ML}px) {
    gap: 10px;
    padding: 20px;
  }
`

export const BlankItem = styled.div`
  height: 185px;
  width: 185px;
  @media (max-width: ${screenSizes.M}px) {
    height: 165px;
    width: 150px;
  }
`
