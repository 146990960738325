import { useDispatch, useSelector } from 'react-redux'
import { setConnectWallet } from '../../../logic/redux/actions'
import { Button } from '../../../shared/button'
import { withTheme } from 'styled-components'
import { Container, Main } from '../../nft/style'
import { FlexCol } from '../../../shared/helpers/styled'
import { BolderText, SmallText } from '../../../shared/Typography'
import { ABOUT_TEXT, DETAILS_ABOUT_TEXT1, DETAILS_ABOUT_TEXT2 } from '../../../shared/helpers/text'
import { ContainerAbout } from './style'

export const ConnectWallet = withTheme((props: any) => {
  const { theme } = props
  const dispatch = useDispatch()
  const globalSelector = useSelector((state: any) => state)
  const { isWrongNetwork } = globalSelector.navbar
  const handleConnectWallet = () => {
    dispatch(setConnectWallet(true))
    document.body.style.overflow = 'hidden'
  }
  return (
    <Container>
      <Main>
        <ContainerAbout>
          <FlexCol padding="0 20px" gap="16px">
            <BolderText fColor={theme.white}>{ABOUT_TEXT}</BolderText>
            <SmallText fontSizeM="14px" fColor={theme.white}>
              {DETAILS_ABOUT_TEXT1}
            </SmallText>
            <SmallText fontSizeM="14px" fColor={theme.white}>
              {DETAILS_ABOUT_TEXT2}
            </SmallText>
          </FlexCol>
          <Button onClick={handleConnectWallet} btnType="ConnectWalletButton">
            {isWrongNetwork ? 'Wrong Network' : 'Connect Wallet'}
          </Button>
        </ContainerAbout>
      </Main>
    </Container>
  )
})
