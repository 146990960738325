import { withTheme } from 'styled-components'
import { FlexCol, FlexLink, SVGIcon } from '../../../shared/helpers/styled'
import { team } from '../../../shared/helpers/config'
import { BigBolderText, SmallText, UnderLineText } from '../../../shared/Typography'
import { ContainerTeam, RoadMapHead, TeamCardsContainer } from '../style'
import { TEAM_TEXT, TWITTER_URL } from '../../../shared/helpers/text'

export const Team = withTheme((props: any) => {
  const { theme } = props

  return (
    <ContainerTeam id="#team">
      <RoadMapHead>
        <BigBolderText fColor={theme.black}>{TEAM_TEXT}</BigBolderText>
      </RoadMapHead>
      <TeamCardsContainer>
        {team.map((el, idx) => {
          return <TeamCard theme={theme} key={idx} member={el} />
        })}
      </TeamCardsContainer>
    </ContainerTeam>
  )
})
interface ITeamCardProps {
  member?: any
  theme?: any
}
const TeamCard = (props: ITeamCardProps) => {
  const { member, theme } = props
  const { name, image, isAdvisor = false } = member
  return (
    <FlexLink href={TWITTER_URL + name} target="_blank" rel="noopener noreferrer" align="center" gap="18px">
      <SVGIcon width="185px" height="185px" heightM="165px" widthM="150px" src={image} alt={`team-avatar-${name}`} />
      <FlexCol align="center" gap="8px">
        <UnderLineText fWeight="700" fSize="20px" fColor={'#000'}>
          @{name}
        </UnderLineText>
        {isAdvisor && <SmallText fColor={theme.black}>(advisor)</SmallText>}
      </FlexCol>
    </FlexLink>
  )
}
