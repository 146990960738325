import { Button } from '../button'
import { SmallText } from '../Typography'
import {
  PickerColorPreview,
  PickerContainer,
  PickerHeader,
  PickerInput,
  PickerInputContainer,
  PickerTextCntr,
  PickerWrapper,
} from './style'

import { SVGIcon, Tooltip } from '../helpers/styled'

import clearIcon from '../../assets/images/clear-icon.svg'
import infoIcon from '../../assets/images/info-circle-outlined.svg'

interface IColorPickerProps {
  value: any
  onChange: any
  headerText?: string
  required?: boolean
  pickerId: string
  canClear?: boolean
  tooltipText: string
  theme: any
  hidePicker?: boolean
}

export const ColorPicker = (props: IColorPickerProps) => {
  const { value, onChange, tooltipText, headerText, required, pickerId, canClear, theme, hidePicker } = props

  return (
    <PickerContainer hidePicker={hidePicker}>
      <PickerHeader>
        <PickerTextCntr>
          <SmallText fWeight="700" fSize="18px" fColor="#fff">
            {headerText}
          </SmallText>
        </PickerTextCntr>
        <Tooltip customWidth="18em" data-tooltip={tooltipText}>
          <SVGIcon height="24px" width="24px" src={infoIcon} />
        </Tooltip>
      </PickerHeader>
      <PickerWrapper>
        <PickerInputContainer isPlaceHolder={!value}>
          <PickerInput id={`color-picker-${pickerId}`} value={value} onChange={onChange} type="color" />
          <PickerColorPreview htmlFor={`color-picker-${pickerId}`}>{value || 'None'}</PickerColorPreview>
        </PickerInputContainer>
      </PickerWrapper>
    </PickerContainer>
  )
}
