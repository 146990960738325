import styled from 'styled-components'
import { screenSizes } from '../../../styles/theme'
import starBG from '../../../assets/images/star-bg.svg'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #000 url(${starBG});
  gap: 2em;
  height: 1200px;
  @media (min-width: 825px) {
    height: 100vh;
  }
`

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  max-width: 1400px;
  height: 100vh;
  padding: 0 2em;
  @media (max-width: ${screenSizes.ML}px) {
    padding: 1em 10px 0em 10px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    padding: 1em 0em 2em 0em;
  }
  padding-top: 64px;
  *::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: ${(props: any) => props.theme.primary};
    border-radius: 20px;
    background-clip: content-box;
  }
`
