import styled, { ThemeProps } from 'styled-components'
import { screenSizes } from '../../../../styles/theme'

import starBG from '../../../../assets/images/star-bg.svg'
export const MintContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(props: ThemeProps<any>) => props.theme.primary};
`

export const MintArea = styled.div`
  display: flex;
  gap: 55px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(86vh - 90px);
  @media (max-width: ${screenSizes.XS}px) {
    gap: 20px;
  }
`
export const TermsCntr = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  max-width: 620px;
  align-items: center;
  @media (max-width: ${screenSizes.XS}px) {
    gap: 20px;
  }
`
interface ITextWrapperProps {
  gap?: boolean
}
export const TextWrapper = styled.div<any>`
  display: flex;
  gap: ${(props: any) => props.gap || '48px'};
  flex-direction: column;
  align-items: center;
  padding: 0px 5px;
  @media (max-width: ${screenSizes.XS}px) {
    gap: 20px;
  }
`
export const TermsBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2px;
  @media (max-width: ${screenSizes.XS}px) {
    flex-direction: column;
    gap: 12px;
  }
`
export const Line = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: ${(props: any) => props.theme.white};
`
export const ContainerMintTail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000e6 url(${starBG});
  height: 14vh;
  gap: 16px;
`
export const LinkIcon = styled.a`
  :hover {
    cusror: pointer;
    img {
      scale: 0.8;
      transition: scale 0.4s ease;
    }
  }
`

export const BrandingImg = styled.img`
  height: 50px;
  width: 700px;
  @media (max-width: ${screenSizes.M}px) {
    height: 22px;
    width: 310px;
  }
`
export const InputContainer = styled.div`
  display: flex;
  gap: 1em;
  margin: auto;
`
interface IMarkingsProps {
  top?: boolean
}
export const FieldMarkingContainer = styled.div<IMarkingsProps>`
  display: flex;
  justify-content: space-around;
  margin: ${(props: IMarkingsProps) => (props.top ? '16px 0 0 0' : '0 0 16px 0')};
  // height: 29px;
  max-height: 29px;
`
export const WarningCntr = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
`
export const TextCntr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`
export const IconCntr = styled.div`
  display: flex;
  gap: 14px;
`
export const DetailsCntr = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: ${screenSizes.ML}px) {
    flex-direction: column;
    gap: 2em;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
`
export const CountCntr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  @media (max-width: ${screenSizes.ML}px) {
    flex-direction: column;
  }
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props: any) => props.theme.black};
  width: 85px;
  height: 80px;
  border-radius: 10px;
  @media (max-width: ${screenSizes.XS}px) {
    width: 70px;
  }
`
export const DarkTile = styled.div`
  width: 260px;
  height: 80px;
  background: ${(props: any) => props.theme.black};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MintDetailsCntr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 130px;
  max-width: 1000px;
  @media (max-width: ${screenSizes.M}px) {
    gap: 70px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    gap: 40px;
  }
`

export const Tile = styled.div`
  background: transparent;
  border: 2px solid ${(props: any) => props.theme.black};
  min-width: 140px;
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`

export const CardBodyTextCntr = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 14px;
  max-height: 250px;
  overflow: auto;
`

export const ComingSoonText = styled.div`
  font-size: 54px;
  margin-top: 2rem;
`
