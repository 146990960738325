import { useState } from 'react'
import { SVGIcon } from '../../../shared/helpers/styled'
import { FAQ_TEXT, GB_DISCORD_URL, TWITTER_URL } from '../../../shared/helpers/text'
import { BigBolderText, BoldSpanInline, SmallText } from '../../../shared/Typography'
import {
  AnchorTextInLine,
  ContainerFAQ,
  FaqAnswerCntr,
  FAQContainer,
  FAQItem,
  Pad,
  RoadMapHead,
  TextWrap,
} from '../style'
import downIcon from '../../../assets/images/down-icon-black.svg'
import upIcon from '../../../assets/images/up-icon.svg'
import logoBlack from '../../../assets/images/logo-black.svg'
import lineIcon from '../../../assets/images/line-icon.svg'
import baDumTissImage from '../../../assets/images/ba-dum-tsss-drum.webp'

export const FAQSection = (props: any) => {
  const { theme } = props
  const [openItem, setOpenItem] = useState<boolean>(false)

  return (
    <ContainerFAQ id="#faq">
      <RoadMapHead>
        <BigBolderText fColor={theme.black}>{FAQ_TEXT}</BigBolderText>
      </RoadMapHead>
      <FAQContainer>
        {/* down-iconWhat is Ghostball? */}
        <FAQListItem0 index={0} setOpenItem={setOpenItem} openItem={openItem} />

        {/* When is the mint date?  */}
        <FAQListItem6 index={6} setOpenItem={setOpenItem} openItem={openItem} />

        {/* How much will it cost? */}
        <FAQListItem7 index={7} setOpenItem={setOpenItem} openItem={openItem} />

        {/* How many Ghostball NFTs can I mint? */}
        <FAQListItem15 index={15} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Do I need a whitelist spot? If so, how do I get one? */}
        {/* <FAQListItem8 index={8} setOpenItem={setOpenItem} openItem={openItem} /> */}

        {/* What is a dynamic NFT? */}
        <FAQListItem16 index={16} setOpenItem={setOpenItem} openItem={openItem} />

        {/* How do I change the color of my dynamic NFT trait(s)? */}
        <FAQListItem17 index={17} setOpenItem={setOpenItem} openItem={openItem} />

        {/* What makes Ghostball different from other NFT pfp projects on Ethereum?  */}
        <FAQListItem1 index={1} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Who are you? And is your team doxxed? */}
        <FAQListItem2 index={2} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Is Ghostball a sports-related project? */}
        <FAQListItem3 index={3} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Do you have a roadmap? */}
        <FAQListItem4 index={4} setOpenItem={setOpenItem} openItem={openItem} />

        {/* What is your vision for the project? What happens after the mint? */}
        <FAQListItem5 index={5} setOpenItem={setOpenItem} openItem={openItem} />

        {/* What's the lore around Ghostball? */}
        <FAQListItem9 index={9} setOpenItem={setOpenItem} openItem={openItem} />

        {/* How will you create the Ghostballs? */}
        <FAQListItem10 index={10} setOpenItem={setOpenItem} openItem={openItem} />

        {/* What rights do I get as a Ghostball holder? */}
        <FAQListItem11 index={11} setOpenItem={setOpenItem} openItem={openItem} />

        {/* What kind of token are you using for your NFTs? */}
        <FAQListItem12 index={12} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Do I need an Ethereum wallet to buy a Ghostball NFT? */}
        <FAQListItem13 index={13} setOpenItem={setOpenItem} openItem={openItem} />

        {/* Do you have any terms & conditions for me to accept? */}
        <FAQListItem18 index={18} setOpenItem={setOpenItem} openItem={openItem} />

        {/* I've made it this far. Will you at least tell me a ghost joke? */}
        <FAQListItem14 index={14} setOpenItem={setOpenItem} openItem={openItem} />
      </FAQContainer>
    </ContainerFAQ>
  )
}
interface IFAQItemProps {
  index?: number
  openItem?: any
  setOpenItem?: any
}
const FAQListItem0 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }

  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What is Ghostball?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Ghostball is a collection of up to 10,000 dynamic generative profile picture NFTs. It's also the umbrella
            project over what we intend to be an ambitious series of initiatives, tools and rewards.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem1 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What makes Ghostball different from other NFT pfp projects on Ethereum?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            We set out to make simple but elegant art that will look good as a pfp on your favorite social media
            platform. We think we've done that.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            But the world doesn't need a copypasta pfp project. We didn't create Ghostball solely for you to flip JPEGs,
            although that can be fun.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Think of our NFTs as an access pass for the tools and ideas we're going to create.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Purchasing our NFT gets you beta access and also gives you a voice in which of our ideas resonate the most.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            But instead of a plain-looking file of a ticket, you're getting cool pfp art.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            <b>Our NFTs will be dynamic. </b>
            Depending on the NFT you mint, you will be able to change the color of a trait to match your favorite sports
            team. You'll even be able to change the metadata on chain to reflect your choice. We've baked some future
            dynamic fun into the code as well.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            <b>Our NFTs are about fun and tech rather than flipping and !floor. </b>
            We're good ghosts, for the most part. We don't intend to haunt your dreams, although we can be mischievous.
            We're focused on providing you with tools, incentives and rewards. We've already worked hard on providing
            further value in 2022, and we anticipate being able to add utility soon after launch. Hint: You might want
            to join a fantasy football league this year … We hear Yahoo! has good app for that.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            <b>Our NFTs are collaborative. </b>
            We gave away 1 ETH and 40 free commemorative NFTs to those who filled out an early survey. We're holding a
            social media contest pre-mint to allow our early fans to determine one of our 1/1s. We'll be surveying our
            community after launch, and we have some cool things planned for our active community members. We want to
            continue the ethos of involving you and your ideas.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem2 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Who are you? And is your team doxxed?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            We are a team of passionate, engaged NFT community members with a long-standing interest and participation
            in crypto and the NFT ecosystem.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            That starts with the founder of Blackbeard Development Group,{' '}
            <AnchorTextInLine href={TWITTER_URL + 'RichardRyan'} target="_blank" rel="noopener noreferrer">
              @RichardRyan
            </AnchorTextInLine>
            .
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Richard has been at the forefront of digital and social since 2005. He has innovated in the space working
            with fortune 500 companies on their creative and marketing strategies along with co-founding Black Rifle
            Coffee Company building out his own digital media brands with over 20 billion views and millions of
            followers.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Richard's business success has enabled us to assemble a talented group.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            As a team, we're grateful to Richard for backing us with the resources we needed to bring Ghostball to life.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Some of the Ghostball team members are doxxed. Some have attached their digital identities to the project.
            And others remain private.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Here's a list of other Ghostball team members, excluding any agencies we've partnered with (Click on the
            links below to visit their twitter accounts):
          </SmallText>
          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'csmithSEC'} target="_blank" rel="noopener noreferrer">
              @csmithSEC
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'ShanaMcInnes'} target="_blank" rel="noopener noreferrer">
              @ShanaMcInnes
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'carsonturner'} target="_blank" rel="noopener noreferrer">
              @carsonturner (advisor)
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'ABigThingBadly'} target="_blank" rel="noopener noreferrer">
              @ABigThingBadly (advisor)
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + '0xfoobar'} target="_blank" rel="noopener noreferrer">
              @0xfoobar (advisor)
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + '0xKeef'} target="_blank" rel="noopener noreferrer">
              @0xKeef
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'KrunchXBT'} target="_blank" rel="noopener noreferrer">
              @KrunchXBT
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + '0xPotamus'} target="_blank" rel="noopener noreferrer">
              @0xPotamus
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'ctrlshiftin'} target="_blank" rel="noopener noreferrer">
              @ctrlshiftin
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'isomorph13'} target="_blank" rel="noopener noreferrer">
              @isomorph13
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'bryanminear'} target="_blank" rel="noopener noreferrer">
              @bryanminear
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'incalstory'} target="_blank" rel="noopener noreferrer">
              @incalstory
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'DanVillageArt'} target="_blank" rel="noopener noreferrer">
              @DanVillageArt
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'TedHyman247'} target="_blank" rel="noopener noreferrer">
              @TedHyman247
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'cesarperezfit'} target="_blank" rel="noopener noreferrer">
              @cesarperezfit
            </AnchorTextInLine>
          </TextWrap>

          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'ShooterMcGavin_'} target="_blank" rel="noopener noreferrer">
              @ShooterMcGavin_
            </AnchorTextInLine>
          </TextWrap>
          <TextWrap>
            <AnchorTextInLine href={TWITTER_URL + 'Blake_NashBR'} target="_blank" rel="noopener noreferrer">
              @Blake_NashBR
            </AnchorTextInLine>
          </TextWrap>

          <Pad />
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem3 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Is Ghostball a sports-related project?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">Yes. What gave it away?</SmallText>
          <SmallText padding="0 0 24px 0">
            We think passion is a defining characteristic of the NFT community. Sports fans exhibit that same passion
            and enthusiasm.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            No matter which ghost identity you take on, we just know you're going to add your own passion for NFTs and
            sports to our community.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            “Sports” is a wide-ranging category covering merch, game-day experiences, media, fandom, fantasy games and
            more. If you can dream it, we can help build it.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Just want to rep your favorite team on game day? Our dynamic NFTs will let you change the color of specific
            traits on chain. Are you a fantasy football fanatic? We'll have something unique for you as well.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem4 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Do you have a roadmap?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Yes and no. A roadmap can suggest an inflexible plan and a time limit with a beginning and an end.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We don't have an end in mind. But we do have some initial ideas that we've spent time working on behind the
            scenes.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            This is a journey. And if we told you everywhere we may go together, it would ruin the surprise.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We have concrete plans and we also want to involve your ideas and feedback. We'll try to strike a balance
            between mystery and excitement.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We'll release a creative roadmap image soon that will offer some hints.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem5 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What is your vision for the project? What happens after the mint?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            We don't want to hand the project off to you as community members and disappear. Nor do we want to be
            dictators.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We have a tool that we've already developed that we'll hand over to the community not long after the mint.
            We're excited about it. (Hope you like blockchain + sports!)
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We also have a few other small plans in place to delight and entertain you while we work on executing our
            next big ideas under the Ghostball umbrella.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We want you to work with us to identify what's next. Perhaps you want to subsidize Little League jerseys for
            a community in need. Maybe we spin out a sports podcast. Maybe we create a machine learning algorithm that
            helps you optimize your fantasy draft. Maybe we start a pickleball league … or not. 👻
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We're excited to see what subcommunities and ideas form from within the community so we can partner with you
            to bring them to life.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            The bottom line: We care more about the passion of our community and providing things that you love than we
            do about !floor.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem6 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            When is the mint date?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Barring any gremlins in the basement, Ghostball will mint near sunset on Halloween.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Watch our{' '}
            <a
              href="https://twitter.com/GhostballNFT"
              target="_blank"
              style={{ color: 'blue', textDecorationLine: 'underline' }}
            >
              Twitter
            </a>{' '}
            and{' '}
            <a
              href="https://discord.com/invite/ghostball"
              target="_blank"
              style={{ color: 'blue', textDecorationLine: 'underline' }}
            >
              Discord
            </a>{' '}
            accounts for the official announcement on Oct. 31.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Only whitelisted users will be able to mint during the first 24 hours. Then the mint will be open to the
            public for an additional 48 hours.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            The Ghostball team will pause the mint after the 48-hour public window even if all 10,000 NFTs haven’t been
            minted.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We reserve the right to re-open the mint at a later date. But token holders from this initial mint will have
            the opportunity to participate in product testing and contribute to the roadmap.{' '}
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We may provide future benefits for holding our early-generation mints.{' '}
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem7 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            How much will it cost?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">Minting a single Ghostball will cost 0.1 ETH plus gas.</SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem8 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Do I need a whitelist spot? If so, how do I get one?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Yes. In an effort to attract a strong holder base of real, active people, we are whitelisting our mint.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            In addition to giving away whitelist spots to NFT communities that we respect, we'll be offering
            opportunities to people who engage with us on social media (follow{' '}
            <AnchorTextInLine href={TWITTER_URL + 'ghostballNFT'} target="_blank" rel="noopener noreferrer">
              @ghostballNFT
            </AnchorTextInLine>{' '}
            and join our{' '}
            <AnchorTextInLine href={GB_DISCORD_URL} target="_blank" rel="noopener noreferrer">
              Discord
            </AnchorTextInLine>
            ) and verify their wallets.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            That said, we don't intend to make you jump through many hoops to buy our NFT.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem9 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What's the lore around Ghostball?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">Do you believe in ghosts? What about good vs. evil?</SmallText>
          <SmallText padding="0 0 24px 0">
            We may not know your answer. But we know ghosts are perfect for sports. They're mysterious, connect us to
            past memories and add an element of superstition.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Whether you want to haunt your rival team, become a legend that never dies (like the ghost of Babe Ruth in
            the movie “The Sandlot”) or defy the laws of physics, we'll do it together.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We've also created a number of Ghostball personalities, which we'll reveal one our mint goes live. Each
            personality will have a different goal within the Ghostball community, so we'll see which one(s) make the
            biggest impact on the ecosystem.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem10 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            How will you create the Ghostballs?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            We will algorithmically generate the Ghostballs by combining traits with varying rarities in different
            categories (or layers).
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem11 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What rights do I get as a Ghostball holder?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            All Ghostball token holders get non-commercial individual use rights (example: print your Ghostball on a
            T-shirt).
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem12 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What kind of token are you using for your NFTs?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            We're minting ERC-721 tokens on the Ethereum network. Our NFTs are composable and bundled. That's a fancy
            way of saying that we have undefined layers within our NFTs that we can incorporate in the future – or that
            you can use to modify your NFT on the blockchain.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem13 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Do I need an Ethereum wallet to buy a Ghostball NFT?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Yes. You do need an Ethereum wallet in order to mint a Ghostball NFT and to eventually access token-gated
            portions of our website and Discord server.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem14 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            I've made it this far. Will you at least tell me a ghost joke?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">Certainly.</SmallText>
          <SmallText padding="0 0 24px 0">Why do ghosts love elevators?</SmallText>
          <SmallText padding="0 0 24px 0">…It lifts their spirits.</SmallText>
          <SVGIcon widthM="300px" width="440px" height="200px" src={baDumTissImage} alt="down-icon" />
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem15 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            How many Ghostball NFTs can I mint?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">We’re limiting the mint to 5 tokens per wallet maximum.</SmallText>
          <SmallText padding="0 0 24px 0">
            This applies whether you mint during the whitelist window, the public mint or both.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            You may mint up to 5 Ghostball NFTs in each wallet that you control.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem16 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            What is a dynamic NFT?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            An NFT is dynamic if it can change some of its inherent properties post-mint.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            In our case, several of our traits are color changeable. That’s right: Don’t like your background color, for
            example? Or the color on your Football Jersey trait? No problem. You can change it on chain to any color you
            want.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem17 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            How do I change the color of my dynamic NFT trait(s)?
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Navigate to “My NFTs” from the ghostballnft.com homepage. Click on the NFT you want to edit (and then the
            trait you want to edit). You’ll get a chance to pick your new color(s) and see a preview before cementing
            your choice on the blockchain.{' '}
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Keep in mind that when you submit a color change transaction, you’ll pay gas + a 0.007 ETH color change fee.
          </SmallText>
          <SmallText padding="0 0 24px 0">
            You can change the color of your dynamic trait(s) any time you want, as many times as you want.{' '}
          </SmallText>
          <SmallText padding="0 0 24px 0">
            Holding a POTM and want to revert back to the original metadata? That’s possible too (same cost).{' '}
          </SmallText>
          <SmallText padding="0 0 24px 0">
            We’ve also baked in some hidden dynamic fun into our NFTs for the future.{' '}
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}

const FAQListItem18 = (props: IFAQItemProps) => {
  const { index, openItem, setOpenItem } = props
  const handleClick = () => {
    if (openItem === index) {
      setOpenItem(-1)
      return
    }
    setOpenItem(index)
  }
  return (
    <li>
      <FAQItem onClick={handleClick}>
        <div>
          <SVGIcon width="18px" height="18px" src={openItem === index ? lineIcon : logoBlack} alt="down-icon" />
          <SmallText fWeight="700" fSize="20px">
            Do you have any terms & conditions for me to accept?{' '}
          </SmallText>
        </div>
        <SVGIcon width="20px" height="12px" src={openItem === index ? upIcon : downIcon} alt="down-icon" />
      </FAQItem>
      {openItem === index && (
        <FaqAnswerCntr>
          <SmallText padding="0 0 24px 0">
            Yes. We’ll ask you to agree to our terms & conditions the first time you connect your wallet to our website.
          </SmallText>
        </FaqAnswerCntr>
      )}
    </li>
  )
}
