import styled from 'styled-components'
import starBG from '../../assets/images/star-bg.svg'
import { LTRAnimationOnHover } from '../../shared/helpers/styled'
import { maxWidth, screenSizes } from '../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  background: #000 url(${starBG});
  gap: 2em;
  height: 1200px;
  @media (min-width: 825px) {
    height: 100vh;
  }
`

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
  gap: 1em;
  height: 100vh;
  padding: 0 2em;
  max-width: ${maxWidth};
  @media (max-width: ${screenSizes.ML}px) {
    padding: 1em 10px 0em 10px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    padding: 1em 0em 2em 0em;
  }
  padding-top: 64px;
  *::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: ${(props: any) => props.theme.primary};
    border-radius: 20px;
    background-clip: content-box;
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 20px;
  @media (max-width: ${screenSizes.ML}px) {
    flex-flow: column;
    align-items: center;
  }
`
export const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 40px;
  @media (max-width: ${screenSizes.ML}px) {
    flex-flow: column;
    align-items: center;
    gap: 45px;
  }
`
interface IAvatarAreaProps {
  isPreview?: boolean
}
export const AvatarArea = styled.div<IAvatarAreaProps>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props: any) => props.theme.fadedWhite};
  padding: ${(props: any) => (props.isPreview ? '24px' : '24px 24px 0 24px')};
  gap: 24px;
  @media (max-width: ${screenSizes.XS}px) {
    padding: 20px 20px 0 20px;
  }
`

export const AtrributesArea = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
`
export const AtrributesHead = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: start;
  @media (max-width: ${screenSizes.M}px) {
    width: 100%;
    justify-content: start;
  }
`
export const NFTAtrributesHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: ${screenSizes.M}px) {
    width: 100%;
    justify-content: start;
  }
`
interface IAttrProps {
  isLoading?: boolean
}
export const AtrributesList = styled.div<IAttrProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 1rem;
  min-width: 700px;
  max-width: 700px;
  justify-items: center;
  @media (max-width: ${screenSizes.XXL}px) {
    min-width: 700px;
    max-width: 800px;
  }
  @media (max-width: ${screenSizes.XL}px) {
    min-width: 500px;
    max-width: 600px;
  }
  @media (max-width: ${screenSizes.L}px) {
    min-width: 300px;
    max-width: 400px;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  }
  @media (max-width: ${screenSizes.ML}px) {
    min-width: 500px;
    max-width: 600px;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  }
  @media (max-width: ${screenSizes.M}px) {
    min-width: 500px;
    max-width: 550px;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  }
  @media (max-width: ${screenSizes.S}px) {
    min-width: 400px;
    max-width: 480px;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  }
  @media (max-width: ${screenSizes.XS}px) {
    min-width: 350px;
    max-width: 370px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  @media (max-width: ${screenSizes.XXS}px) {
    min-width: 300px;
    max-width: 340px;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  }
  max-height: 410px;
  height: ${(props: any) => (props.isLoading ? '410px' : '')};
  overflow-x: auto;
  padding-right: 12px;
`

export const AttributeBox = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 95px;
  justify-content: center;
  align-items: center;
  background: ${(props: any) => props.theme.fadedWhite};
  max-width: 160px;
  width: 160px;
  border-radius: 10px;
  padding: 25px;
  gap: 8px;
  .attributeText {
    display: block;
    text-align: center;
  }
  .attributeBtn {
    display: none;
    // animation: fade-out 300ms forwards;
  }
  .hiddenBtn {
    display: none;
  }

  overflow: hidden;

  @media (max-width: ${screenSizes.L}px) {
    max-width: 130px;
    width: 130px;
  }
  @media (max-width: ${screenSizes.ML}px) {
    max-width: 170px;
    width: 170px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    max-width: 160px;
    width: 160px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 150px;
    width: 150px;
  }
`
export const AnimatedAttributeBox = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 95px;
  justify-content: center;
  align-items: center;
  background: ${(props: any) => props.theme.fadedWhite};
  max-width: 160px;
  width: 160px;
  border-radius: 10px;
  padding: 25px;
  gap: 8px;
  .attributeText {
    display: block;
  }
  .attributeBtn {
    display: none;
    // animation: fade-out 300ms forwards;
  }
  .hiddenBtn {
    display: none;
  }
  :hover,
  :focus {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(80, 80, 80, 0.9) 93.23%);
    box-shadow: 0px 0px 5px ${(props: any) => props.theme.black};
    cursor: pointer;
    .attributeText {
      display: none;
    }
    .attributeBtn {
      display: block;
      animation: slide-up 1000ms forwards, fade-in 500ms forwards;
    }
  }
  overflow: hidden;
  ${LTRAnimationOnHover};

  @media (max-width: ${screenSizes.L}px) {
    max-width: 130px;
    width: 130px;
  }
  @media (max-width: ${screenSizes.ML}px) {
    max-width: 170px;
    width: 170px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    max-width: 160px;
    width: 160px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 150px;
    width: 150px;
  }
`
export const GreenTile = styled.div`
  background: ${(props: any) => props.theme.primary};
  border-radius: 10px;
  padding: 6px 12px;
  font-style: italic;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const NFTAvatar = styled.img`
  width: 340px;
  height: 405px;
  border-radius: 5px;
  @media (max-width: ${screenSizes.M}px) {
    width: 88vw;
    height: 550px;
  }

  @media (max-width: ${screenSizes.XS}px) {
    width: 80vw;
    height: 400px;
  }
`
interface IImageProps {
  maxwidth?: string
  width?: string
  widthM?: string
  height?: string
  heightM?: string
  maxwidthM?: string
  borderRadius?: string
}
export const SVGImage = styled.img<IImageProps>`
  width: ${(props: any) => props.width || '65px'};
  height: ${(props: any) => props.height || '65px'};
  border-radius: ${(props: any) => props.borderRadius || '5px'};
  max-width: 270px;
  @media (max-width: ${screenSizes.M}px) {
    width: ${(props: any) => props.widthM};
    height: ${(props: any) => props.heightM};
  }
`
export const LayerDescription = styled.div`
  display: flex;
  flex-flow: column;
  // gap: 8px;
  @media (max-width: ${screenSizes.XXL}px) {
    max-width: 800px;
  }
  @media (max-width: ${screenSizes.XL}px) {
    max-width: 600px;
  }
  @media (max-width: ${screenSizes.L}px) {
    max-width: 400px;
  }
  @media (max-width: ${screenSizes.ML}px) {
    max-width: 300px;
  }
  @media (max-width: ${screenSizes.M}px) {
    max-width: 90vw;
  }
  @media (max-width: ${screenSizes.XS}px) {
    max-width: 340px;
  }
`

export const EditArea = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  gap: 30px;
  max-width: 850px;
  @media (max-width: ${screenSizes.ML}px) {
    width: 100%;
    align-items: start;
  }
  @media (max-width: ${screenSizes.M}px) {
    max-width: 460px;
  }
`
interface ISVGProps {
  customCss?: string
}
export const SVGElement = styled.div<ISVGProps>`
  height: 410px;
  width: auto;
  min-width: 410px;
  svg {
    height: 410px;
    width: auto;
    border-radius: 5px;
    overflow: hidden;
    ${(props: any) => props.customCss}
    @media (max-width: ${screenSizes.XS}px) {
      max-width: 320px;
      height: auto;
    }
    @media (max-width: ${screenSizes.XXS}px) {
      max-width: 300px;
      height: auto;
      min-width: 300px;
    }
  }
  @media (max-width: ${screenSizes.XS}px) {
    max-width: 320px;
    height: auto;
    min-width: 320px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 300px;
    height: auto;
    min-width: 300px;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  @media (max-width: ${screenSizes.ML}px) {
    width: 100%;
  }
`
