import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ThemeProps, withTheme } from 'styled-components'
import Tilt from 'react-parallax-tilt'

import {
  BtnContainer,
  DashboardContainer,
  DivContainer,
  DivWrapper,
  ImageContainer,
  LinkText,
  NFTCardContainer,
  NFTListContainer,
  NoNFTsContainer,
  SVGElement,
  SVGImage,
  TextWrapper,
  Wrapper,
} from './style'
import { b64ToString } from '../../shared/helpers/util'
import { composableAddress } from '../../blockchain/abi/ComposableNFT'
import { BolderText, WalletText } from '../../shared/Typography'
import { ComposableNFT } from '../../blockchain/instance'
import { Button } from '../../shared/button'
import { SVGIcon } from '../../shared/helpers/styled'
import { MINT_NFTS_TEXT, NFTS_IN_WALLET_TEXT, NO_NFTS_TEXT } from '../../shared/helpers/text'
import { homePath } from '../../logic/paths'

import openInNew from '../../assets/images/open-in-new-icon.svg'
import openInNewBlack from '../../assets/images/open-in-new-icon-black.svg'
import { ConnectWallet } from '../app/navbar/ConnectWallet'
import { FETCH_NFTS_URI } from '../../shared/helpers/config'

export const NFTDashboard: React.FC = withTheme((props: ThemeProps<any>) => {
  const { theme } = props
  const { account, active, library, chainId } = useWeb3React()
  const [userNFTs, setUserNFTs] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (library) {
      setIsLoading(false)
      init()
    } else {
      setIsLoading(true)
    }
  }, [library, account])

  const init = async () => {
    try {
      setIsLoading(true)
      let url = `${FETCH_NFTS_URI}/${account}/nft?token_addresses=${composableAddress}`

      // if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') {
      //   url = `${FETCH_NFTS_URI}/${account}/nft?chain=0X${chainId}&format=decimal&token_addresses=${composableAddress}`
      // }

      const { data } = await axios.get(url, {
        headers: {
          'X-API-Key': 'test',
          accept: 'application/json',
        },
      })
      const { result } = data
      setUserNFTs(result)
      setIsLoading(false)
    } catch (error) {
      console.error('error initializing', error)
      setIsLoading(false)
    }
  }
  if (!account) {
    navigate(homePath)
  }
  return (
    <DashboardContainer>
      <DivContainer>
        <Wrapper>
          <TextWrapper>
            <BolderText fontSizeM="28px" fLineHeightMb="32px" fColor={theme.white}>
              {NFTS_IN_WALLET_TEXT}
            </BolderText>
            <span>{userNFTs?.length || 0}</span>
          </TextWrapper>
          <DivWrapper>
            {isLoading ? (
              <BolderText fColor={theme.white}>Loading...</BolderText>
            ) : userNFTs.length ? (
              <NFTListContainer>
                {userNFTs.map((nft: any, index: number) => {
                  return <UserNFT theme={theme} nft={nft} key={index} />
                })}
              </NFTListContainer>
            ) : (
              <NoNFTs theme={theme} />
            )}
          </DivWrapper>
        </Wrapper>
      </DivContainer>
    </DashboardContainer>
  )
})

const UserNFT = (props: any) => {
  const { library } = useWeb3React()
  const navigate = useNavigate()

  const { nft, theme } = props
  const nftId = nft.token_id

  //* Added to handle the case where the user has a NFT but the metadata takes time to load [DAVE_UPDATE]
  const [nftImageSrc, setNftImageSrc] = useState<any>('')
  const [isHovering, setIsHovering] = useState(false)

  const handleClick = () => {
    // * Check if the nft is special nft or pre reveal animation. [DAVE_UPDATE]
    if (nftImageSrc.startsWith('https://')) {
      // * If it is a special nft or pre reveal nft you cannot edit it. [DAVE_UPDATE]
      console.log('nft not reveled yet or this is a special nft')
    } else {
      navigate(`/nfts/${nftId}`)
    }
  }

  useEffect(() => {
    if (library) init()
  }, [library])

  const init = async () => {
    try {
      const tokenB64 = await ComposableNFT.methods.tokenURI(nftId).call()
      const tokenDetails = JSON.parse(b64ToString(tokenB64))
      // * you dont have to decode the base64 string to Use it as a img src. [DAVE_UPDATE]
      let tokenImgJSON = tokenDetails.image
      console.log(tokenDetails)
      if (tokenDetails.image.startsWith('ipfs://')) {
        // * If the image is an ipfs link, we need to convert it to a url. [DAVE_UPDATE]
        console.log(typeof tokenDetails.image)
        tokenImgJSON = 'https://ipfs.io/ipfs/' + tokenDetails.image.split('://')[1]
        console.log(tokenImgJSON)
      } else if (tokenDetails.image.startsWith('https://')) {
        // * if the image is not an ipfs link, we can use it as is.
        tokenImgJSON = tokenDetails.image
      }
      console.log(tokenImgJSON)
      setNftImageSrc(tokenImgJSON)
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <Tilt tiltReverse={true}>
      {/* do not change courser if the nft is pre revealed or a special nft as it cannot be edited [DAVE_UPDATE] */}
      <NFTCardContainer
        onClick={handleClick}
        style={{
          cursor: `${nftImageSrc.startsWith('https://') ? 'default' : 'pointer'}`,
        }}
      >
        <ImageContainer>
          {/* Using an image tag to accomidate pre reveal art and special nft art  [DAVE_UPDATE]*/}
          {nftImageSrc.endsWith('/0') ? (
            <SVGElement
              dangerouslySetInnerHTML={{
                __html: `<video muted  autoplay="true" width="100%" loop="true"> 
                <source src="https://imgs.ticketaura.lol/0_test"
                type="video/mp4">
                </video>`,
              }}
            />
          ) : (
            <SVGElement
              dangerouslySetInnerHTML={{
                __html: `<img width="100%" src="${nftImageSrc}" />`,
              }}
            />
          )}
          <BtnContainer>
            <Button
              fSize="12px"
              customBgColor={theme.lightBlack}
              btnType="tileButton"
              onMouseLeave={() => setIsHovering(false)}
              onMouseover={() => setIsHovering(true)}
            >
              {isHovering ? (
                <SVGIcon width="16px" height="16px" src={openInNewBlack} />
              ) : (
                <SVGIcon width="16px" height="16px" src={openInNew} />
              )}
              Opensea
            </Button>
          </BtnContainer>
        </ImageContainer>
        <WalletText fontSizeM="20px" fWeight="700">
          Ghostball #{nftId}
        </WalletText>
      </NFTCardContainer>
    </Tilt>
  )
}

const NoNFTs = (props: any) => {
  const { theme } = props
  return (
    <NoNFTsContainer>
      <WalletText fColor={theme.navPrimaryText}>{NO_NFTS_TEXT}</WalletText>
      <span>
        <LinkText to={homePath} data-content="Mint">
          Mint
        </LinkText>{' '}
        {MINT_NFTS_TEXT}
      </span>
    </NoNFTsContainer>
  )
}
