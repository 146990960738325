import styled from 'styled-components'
import starBG from '../../assets/images/star-bg.svg'
import { LTRAnimationOnHover } from '../../shared/helpers/styled'
import { maxWidth, screenSizes } from '../../styles/theme'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  background: #000;
  #LayerBackground {
    color: red;
  }
  gap: 2em;
  @media (min-width: ${screenSizes.M}px) {
    height: 100vh;
  }
`
export const DashboardContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  background: #000;
  gap: 2em;
`
export const NFTCardContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props: any) => props.theme.fadedWhite};
  padding: 12px;
  color: white;
  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px ${(props: any) => props.theme.white};
    p {
      color: ${(props: any) => props.theme.primary};
    }
  }
  overflow: hidden;
  gap: 14px;
  ${LTRAnimationOnHover};
`
export const DivContainer = styled.div`
  min-height: 100vh;
`
export const DivWrapper = styled.div`
  width: 100%;
`
export const NoNFTsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  span {
    color: ${(props: any) => props.theme.white};
    font-size: 28px;
    font-style: italic;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
  }
`
export const NFTListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 1rem;
  max-width: ${maxWidth};
  justify-items: start;
  @media (max-width: ${screenSizes.XL}px) {
    max-width: 90vw;
  }
  @media (max-width: ${screenSizes.S}px) {
    justify-items: center;
  }
  padding-bottom: 5em;
`
interface IImageProps {
  maxwidth?: string
  width?: string
  widthM?: string
  height?: string
  heightM?: string
  maxwidthM?: string
  borderRadius?: string
  minWidth?: string
}

export const SVGImage = styled.img<IImageProps>`
  width: ${(props: any) => props.width || '65px'};
  height: ${(props: any) => props.height || '65px'};
  min-width: ${(props: any) => props.minWidth};
  border-radius: ${(props: any) => props.borderRadius || '5px'};
  max-width: 270px;
  @media (max-width: ${screenSizes.M}px) {
    width: ${(props: any) => props.widthM};
    height: ${(props: any) => props.heightM};
  }
  z-index: 2;
`
export const SVGElement = styled.div<IImageProps>`
  height: 255px;
  min-width: 255px;
  max-width: 255px;
  border-radius: 5px;
  z-index: 2;
  svg {
    height: 255px;
    width: auto;
    border-radius: 5px;
    overflow: hidden;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: ${maxWidth};
  padding-top: 4em;
  align-items: center;
  gap: 50px;
`

export const TextWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  @media (max-width: ${screenSizes.XL}px) {
    max-width: 90vw;
    align-items: center;
    max-height: 45px;
  }
  span {
    background: ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.black};
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    padding: 10px 14px;
    border-radius: 15px;
  }
`
export const ImageContainer = styled.div`
  position: relative;
  z-index: 2;
`
export const BtnContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 12px;
`

export const LinkText = styled(Link)`
  position: relative;
  display: inline-block;
  color: ${(props: any) => props.theme.white};
  font-size: 28px;
  font-style: italic;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-decoration: underline;
  :hover {
    text-decoration: none;
  }
  :hover::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  ::before {
    position: absolute;
    content: attr(data-content);
    color: ${(props: any) => props.theme.primary};
    text-decoration: underline;
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    transition: clip-path 700ms ease;
  }
`

export const AnchorText = styled.a`
  position: relative;
  display: inline-block;
  color: ${(props: any) => props.theme.black};
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  text-decoration: underline;
  :hover {
    font-weight: 700;
    scale: 1.2;
    backdrop-filter: blur(5px);
    padding: 2px 5px;
  }
  :hover::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  ::before {
    position: absolute;
    content: attr(data-content);
    color: ${(props: any) => props.theme.black};
    text-decoration: underline;
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    transition: clip-path 700ms ease;
  }
`
