import { withTheme } from 'styled-components'
import { privacyPolicyPath } from '../../../logic/paths'
import { TermsBoldText, TermsText } from '../../../shared/Typography'
import { AnchorInLine } from '../../home/style'
import { CardBodyTextCntr, Container, Main } from '../privacyPolicy/style'

export const Terms = withTheme((props: any) => {
  return (
    <Container>
      <Main>
        <CardBodyTextCntr>
          <TermsBody />
        </CardBodyTextCntr>
      </Main>
    </Container>
  )
})

export const TermsBody = () => {
  return (
    <>
      <TermsBoldText>1. Acceptance of Terms</TermsBoldText>
      <TermsText>
        These Website Terms of Use (these <b>“Terms”</b>) govern your access to websites and related services operated
        by or on behalf of Ghostball, LLC (<b>“Ghostball”</b> <b>“we”</b> or <b>“us”</b>). These Terms are important and
        affect your legal rights, so please read them carefully. Note this Agreement contains a mandatory arbitration
        provision that requires the use of arbitration on an individual basis and limits the remedies available to you
        in the event of certain disputes.
      </TermsText>
      <TermsText>
        By accessing or using{' '}
        <AnchorInLine href="https://www.ghostballnft.com/" target="_blank" rel="noopener noreferrer">
          ghostballnft.com
        </AnchorInLine>{' '}
        and our various related websites and services (collectively, the
        <b>“Ghostball Services”</b>), you agree to be bound by these Terms and all terms incorporated herein by
        reference.
      </TermsText>
      <TermsText>
        You must be 18 years of age or older to use the Ghostball Services. By accepting these Terms, you represent that
        you have the legal authority to do so, and that, if you have accepted these Terms on behalf of any person or
        entity, you represent that you have legal authority to do so and that such person or entity agrees to be
        responsible to us if you or such person or entity violates these Terms. If you do not agree to every provision
        of these Terms, you may not, and we do not authorize you to, access or use the Ghostball Services.
      </TermsText>
      <TermsText>
        By accessing or using the Ghostball Services, you represent and warrant that you have not been previously
        suspended or removed from the Ghostball Services or engaged in any activity that could result in suspension or
        removal from the Ghostball Services.
      </TermsText>
      <TermsText>
        We may revise these Terms at any time for any reason and may provide you notice of these changes by any
        reasonable means, including through the Ghostball Services. You can determine when we last updated these Terms
        by referring to the “Last Updated” legend at the top of these Terms. Except to the extent that your express
        consent to any revised Terms is required under applicable law, by continuing to access, browse or use the
        Ghostball Services, you agree to any revised Terms. If you do not agree to any revised Terms, you may not and we
        do not authorize you to use the Ghostball Services. We strongly recommend that you periodically visit this page
        to review these Terms.
      </TermsText>
      <TermsBoldText>2. User Registration</TermsBoldText>
      <TermsText>
        To access and use certain areas or features of the Ghostball Services, you may need to provide certain
        information and/or answer certain questions. Each registration is for a single user only. By providing
        information to us, you agree to (a) provide accurate, current and complete information, (b) maintain and
        promptly update, as necessary, your information, (c) be responsible for the acts or omissions of any third party
        who has authority to access or use the Ghostball Services on your behalf, and (d) immediately notify us if you
        discover or otherwise suspect any security breaches related to the Ghostball Services. If you provide
        information that is untrue, inaccurate, not current or incomplete, we may suspend and/or terminate your current
        or future use of the Ghostball Services.
      </TermsText>
      <TermsText>
        By providing information and/or answering questions through the Ghostball Services, you also consent to receive
        electronic communications from Ghostball (e.g., via email or through the Ghostball Services). You should
        maintain copies of electronic communications from us by printing a paper copy or saving an electronic copy.
      </TermsText>
      <TermsText>
        We may also send you promotional communications via email, including, but not limited to, newsletters, special
        offers, surveys and other news and information we think will be of interest to you. You may opt out of receiving
        these promotional emails at any time by following the unsubscribe instructions provided therein.
      </TermsText>
      <TermsBoldText>3. Right to Access and Use the Ghostball Services and Content</TermsBoldText>
      <TermsText>
        Unless otherwise indicated in writing by us, the Ghostball Services and all content and other materials
        contained therein, including, without limitation, any Ghostball service mark or logo and all designs, text,
        graphics, pictures, information, data, software, other files and the selection and arrangement thereof
        (collectively, <b>“Content”</b>) are the property of Ghostball or our licensors or users, as applicable, and are
        protected by U.S. and international copyright and other laws.
      </TermsText>
      <TermsText>
        Subject to these Terms, you are hereby granted a limited personal, revocable, non-exclusive, non-transferable,
        non-sublicensable right to access, view and make personal, non-commercial use of the Ghostball Services and
        Content. All rights not expressly granted herein are reserved. You do not acquire any ownership interest in the
        Ghostball Services or Content under these Terms, or any other rights thereto other than to use the Ghostball
        Services in accordance with the rights granted by, and subject to all terms, conditions and restrictions of
        these Terms.
      </TermsText>
      <TermsText>
        You do not have any right to, and you shall not, directly or indirectly: (a) sell, resell or use commercially
        the Ghostball Services or Content, (b) distribute, publicly perform or publicly display the Ghostball Services
        or any Content, (c) modify or otherwise make any derivative uses of the Ghostball Services or Content, or any
        portion thereof, (d) use any data mining, robots or similar data gathering or extraction methods, (e) download
        (other than page caching) any portion of the Ghostball Services or Content, except as expressly permitted by us,
        (f) use the Ghostball Services to stalk, threaten, or otherwise violate the rights of others, including without
        limitation others' privacy rights; (g) interfere with the Ghostball Services or servers or networks used in
        connection with the Ghostball Services; or (h) use the Ghostball Services or Content other than for their
        intended purposes.
      </TermsText>
      <TermsText>
        Any use of the Ghostball Services or Content other than as specifically authorized herein, without our prior
        written permission, is strictly prohibited and will terminate the right of access and use granted herein. Such
        unauthorized use may also violate applicable laws, including, without limitation, copyright and trademark laws
        and applicable communications regulations and statutes. Except as expressly permitted herein, nothing in these
        Terms shall be construed as conferring any right or license to any patent, trademark, copyright or other
        proprietary rights of Ghostball or any third party, whether by estoppel, implication or otherwise.
      </TermsText>
      <TermsText>
        You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices
        incorporated in or accompanying Content and you will not reproduce, modify, adapt, prepare derivative works
        based on, perform, display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit
        Content.
      </TermsText>
      <TermsText>
        We reserve the right in our sole discretion to modify, suspend or discontinue, temporarily or permanently, the
        Ghostball Services (or any features or parts thereof) at any time.
      </TermsText>
      <TermsBoldText>4. Trademarks</TermsBoldText>
      <TermsText>
        The Ghostball logo, and any other Ghostball product or service names, trademarks, logos, or other indicia that
        may appear on the Ghostball Services (<b>“Marks”</b>) are the property of Ghostball or its subsidiaries,
        affiliates or third parties, and may not be copied, imitated or used, in whole or in part, without our prior
        written permission. Nothing contained in these Terms and/or the Ghostball Services shall be construed as
        granting, by implication or otherwise, any license or right to use any such Marks without the prior written
        permission of Ghostball or such third party that may own such Marks.
      </TermsText>
      <TermsBoldText>5. Legal Requirements; Privacy Policy</TermsBoldText>
      <TermsText>
        Our Privacy Policy describes how we handle the personal information you provide to us when you use the Ghostball
        Services. For an explanation of our privacy practices, please visit our Privacy Policy located at{' '}
        <AnchorInLine href={privacyPolicyPath} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </AnchorInLine>
      </TermsText>
      <TermsBoldText>6. User Content</TermsBoldText>
      <TermsText>
        You are solely responsible and liable for all data, information, and other materials (<b>“User Content”</b>)
        that you submit, upload, post, e-mail or otherwise transmit (<b>“Transmit”</b>) in connection with the Ghostball
        Services. In addition, we have no control over, and shall have no liability for, any damages resulting from the
        use (including without limitation, republication) or misuse by any third party of information made public
        through the Ghostball Services. IF YOU CHOOSE TO SUBMIT TO US, OR OTHERWISE MAKE ANY USER CONTENT PUBLICLY
        AVAILABLE, YOU DO SO AT YOUR OWN RISK AND YOU UNDERSTAND AND AGREE THAT WE WILL HAVE NO LIABILITY RELATED TO
        SUCH ACTIVITY.
      </TermsText>
      <TermsText>
        You agree that you will not, and will not permit anyone else to, directly or indirectly: (a) Transmit any User
        Content that is unlawful, harmful, threatening, abusive, hateful, obscene, harassing, tortious, defamatory,
        libelous, slanderous, pornographic, profane, vulgar, offensive, lewd, invasive of another 's privacy or
        racially, ethnically or otherwise objectionable; (b) Transmit any User Content: (i) that you do not have the
        right to Transmit, under any law or contractual or fiduciary relationships, including, without limitation, any
        inside information or proprietary or confidential information; (ii) that infringes any patent, copyright,
        trademark or other intellectual property right or misappropriates any trade secret or right of privacy of any
        third-party; (iii) that constitutes unsolicited or unauthorized advertising or promotional materials, “spam,”
        “chain letters,” or pyramid schemes; or (iv) that contains any software routine, code, instruction or virus that
        is designed to disable, delete, modify, damage or erase software, hardware or data; or (c) forge headers or
        otherwise manipulate identifiers in order to disguise any User Content Transmitted through the Ghostball
        Services.
      </TermsText>
      <TermsText>
        Although we have no obligation to screen, edit or monitor User Content, we reserve the right, and have absolute
        discretion, to remove, screen or edit User Content posted or stored on the Ghostball Services at any time and
        for any reason, and you are solely responsible for creating backup copies of and replacing any User Content you
        post or store on the Ghostball Services at your sole cost and expense.
      </TermsText>
      <TermsBoldText>7. Rights in User Content</TermsBoldText>
      <TermsText>
        We do not claim any ownership interest in User Content. However, by uploading, posting or submitting User
        Content to the Ghostball Services or to our pages or feeds on third-party social media platforms (e.g.,
        Ghostball's Facebook page, LinkedIn page or Twitter feed), you hereby grant Ghostball a nonexclusive,
        royalty-free, worldwide, perpetual, irrevocable and fully sublicensable right and license to use, reproduce,
        modify, adapt, publish, translate, create derivative works from, distribute, perform and publicly display your
        User Content, in whole or in part, in any manner or media and for any purpose whatsoever at our sole discretion,
        including, without limitation, for publicity, promotional, advertising, trade, business, illustration, artistic
        and other commercial and noncommercial purposes. However, Ghostball will only share personal information that
        you provide in accordance with our Privacy Policy.
      </TermsText>
      <TermsText>
        You agree that Ghostball may disclose or use any User Content for any purposes permitted under applicable law,
        including, but not limited to: (a) enforcing these Terms; (b) complying with any laws, regulations or rules of
        any federal, state or local government or agency; (c) responding to claims that any User Content violates the
        rights of third parties; or (d) protecting the rights or property of Ghostball, its customers or the public.
      </TermsText>
      <TermsBoldText>8. Feedback</TermsBoldText>
      <TermsText>
        Separate and apart from User Content, you can submit questions, comments, suggestions, ideas, original or
        creative materials or other information about Ghostball, or the Ghostball Services (collectively, “Feedback”).
        Feedback shall become the sole property of Ghostball. Ghostball shall own exclusive rights, including, without
        limitation, all intellectual property rights, in and to Feedback and shall be entitled to the unrestricted use
        and dissemination of Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation
        to you.
      </TermsText>
      <TermsBoldText>9. Third-Party Sites</TermsBoldText>
      <TermsText>
        We have not reviewed all of the websites linked to the Ghostball Services and are not responsible for the
        content of any third-party pages, any other websites linked to the Ghostball Services, or any products or
        services offered by third parties. Nothing in the Ghostball Services, including, without limitation, any links
        to other websites, should be construed as an endorsement by Ghostball of any products, services or information
        of any other persons or companies. Your choice to access a link to any other website is at your own risk, and
        you agree to comply with all terms and conditions relating to such websites. Ghostball reserves the right not to
        link, or to remove the link, to a particular website at any time.
      </TermsText>
      <TermsText>
        Any links to third-party websites are provided as a convenience to you and are neither owned nor operated by
        Ghostball. We have no control over these linked websites and make no representations or warranties with respect
        to these linked websites or third-party products or services. Your viewing and use of any third-party websites
        is at your sole discretion and risk.
      </TermsText>
      <TermsBoldText>10. Indemnification</TermsBoldText>
      <TermsText>
        You shall indemnify, hold harmless, and, at Ghostball 's option, defend Ghostball and its affiliates and
        subsidiaries from and against any and all losses, damages, liabilities, costs (including reasonable attorneys '
        fees) (“Losses”) incurred by Ghostball resulting from any third-party claim, suit, action or proceeding relating
        to or arising from your use of the Ghostball Services, any User Content, any Feedback you provide, any violation
        of these Terms by you, or any other act or omission by you, including your violation of any rights of another,
        arising from your use of the Ghostball Services or any of its features. You further agree that Ghostball shall
        have control of the defense or settlement of any third-party claims unless Ghostball exercises its option to
        require you to defend Ghostball. This indemnity is in addition to, and not in lieu of, any other indemnities set
        forth in a written agreement between you and Ghostball.
      </TermsText>
      <TermsBoldText>11. General Disclaimers</TermsBoldText>
      <TermsText>
        THE GHOSTBALL SERVICES AND THE CONTENT ARE PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS.
        TO THE FULLEST EXTENT PERMISSIBLE BY LAW, GHOSTBALL DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES OR
        ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: (A) THE GHOSTBALL SERVICES; (B) CONTENT; (C)
        USER CONTENT; OR (D) SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO OR FROM THE GHOSTBALL SERVICES.
        GHOSTBALL HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, THAT ANY RESULTS WILL BE ACHIEVED, NON-INFRINGEMENT, TITLE,
        CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.
      </TermsText>
      <TermsText>
        GHOSTBALL DOES NOT REPRESENT OR WARRANT THAT THE GHOSTBALL SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED; THAT
        DEFECTS WILL BE CORRECTED; OR THAT THE GHOSTBALL SERVICES OR THE SERVERS THAT MAKE THE GHOSTBALL SERVICES
        AVAILABLE ARE FREE FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. GHOSTBALL DOES NOT MAKE
        ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE GHOSTBALL SERVICES IS
        ACCURATE, COMPLETE, OR USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE GHOSTBALL SERVICES IS AT YOUR SOLE RISK.
        GHOSTBALL DOES NOT WARRANT THAT YOUR USE OF THE GHOSTBALL SERVICES IS LAWFUL IN ANY PARTICULAR JURISDICTION.
        GHOSTBALL SPECIFICALLY DISCLAIMS ALL SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF
        IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW
        IS APPLICABLE TO YOU AND THESE TERMS.
      </TermsText>
      <TermsText>
        BY ACCESSING OR USING THE GHOSTBALL SERVICES YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY
        JURISDICTION WHERE YOU ACCESS OR USE THE GHOSTBALL SERVICES.
      </TermsText>
      <TermsBoldText>12. Limitation of Liability; Waiver</TermsBoldText>
      <TermsText>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL GHOSTBALL BE LIABLE FOR ANY INDIRECT, SPECIAL,
        INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE, INCOME OR
        PROFITS, LOSS OF USE OR DATA, LOSS OR DIMINUTION IN VALUE OF ASSETS OR SECURITIES, OR DAMAGES FOR BUSINESS
        INTERRUPTION) ARISING OUT OF OR IN ANY WAY RELATED TO THE ACCESS TO OR USE OF THE GHOSTBALL SERVICES (INCLUDING,
        BUT NOT LIMITED TO, USER CONTENT AND LINKS TO THIRD-PARTY WEBSITES), OR THE ORDER, RECEIPT OR USE OF ANY PRODUCT
        OR SERVICE, OR OTHERWISE RELATED TO THESE TERMS (INCLUDING, BUT NOT LIMITED TO, ANY DAMAGES CAUSED BY OR
        RESULTING FROM RELIANCE ON ANY INFORMATION OBTAINED FROM GHOSTBALL, OR FROM EVENTS BEYOND GHOSTBALL 'S
        REASONABLE CONTROL, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT
        LIMITED TO, SIMPLE NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED) OR ANY OTHER LEGAL OR EQUITABLE THEORY, EVEN
        IF GHOSTBALL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
        FORESEEABLE).
      </TermsText>
      <TermsText>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF
        GHOSTBALL ARISING OUT OF OR IN ANY WAY RELATED TO THE GHOSTBALL SERVICES EXCEED ONE HUNDRED DOLLARS ($100). THE
        FOREGOING LIMITATIONS SHALL APPLY EVEN IN THE EVENT YOUR REMEDIES HEREUNDER FAIL OF THEIR ESSENTIAL PURPOSE, AND
        THE FOREGOING SHALL CONSTITUTE GHOSTBALL 'S SOLE LIABILITY AND OBLIGATION IN RESPECT HEREOF.
      </TermsText>
      <TermsText>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE 1542, WHICH STATES “A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST
        IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY
        AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
      </TermsText>
      <TermsBoldText>13. Arbitration</TermsBoldText>
      <TermsText>
        PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS
        WITH GHOSTBALL AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
      </TermsText>
      <TermsText>
        YOU AND GHOSTBALL AGREE THAT ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING IN ANY WAY TO THESE
        TERMS OR THE GHOSTBALL SERVICES SHALL BE FINALLY DECIDED BY BINDING ARBITRATION UNDER THE RULES OF THE AMERICAN
        ARBITRATION ASSOCIATION GOVERNING CONSUMER DISPUTES.
      </TermsText>
      <TermsText>
        Arbitration uses a single, neutral arbitrator to decide a dispute (instead of a judge or jury); arbitration
        allows for more limited discovery than in a court case; and the arbitration process and result is subject to
        very limited review by courts. In an arbitration you have the right, at your expense, to be represented by an
        attorney of your choosing. Arbitrators can award the same damages and relief under these Terms that a court can
        award under these Terms. You and Ghostball agree that any in-person arbitral hearing would occur in the United
        States in the same county and state as your billing address. Ghostball further agrees that your filing fee for
        an arbitration will be capped at the amount set by the American Arbitration Association. You agree that, by
        agreeing to these Terms, the U.S. Federal Arbitration Act governs the interpretation and enforcement of this
        provision, and that you and Ghostball are each waiving the right to a trial by jury and/or to participate in a
        class action. This arbitration provision shall survive termination of these Terms and the termination of your
        use of the Ghostball Services. Further, unless both you and Ghostball agree otherwise, the arbitrator may not
        join or consolidate more than one person 's claims with your claims and may not otherwise preside over any form
        of a representative or class proceeding. If this specific provision is found to be unenforceable, then the
        entirety of this arbitration provision shall be null and void. The arbitrator may award declaratory or
        injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to
        provide relief warranted by that party 's individual claim.
      </TermsText>
      <TermsBoldText>14. Class Action Waiver</TermsBoldText>
      <TermsText>
        REGARDLESS OF THE FORUM, YOU AND GHOSTBALL AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
        ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        PROCEEDING.
      </TermsText>
      <TermsBoldText>15. Termination</TermsBoldText>
      <TermsText>
        Notwithstanding anything contained in these Terms, we reserve the right, without notice and in our sole
        discretion, to terminate your right to access or use the Ghostball Services at any time and for any or no
        reason, and you acknowledge and agree that in such event we shall have no liability or obligation to you.
      </TermsText>
      <TermsBoldText>16. Governing Law and Jurisdiction</TermsBoldText>
      <TermsText>
        Ghostball operates the Ghostball Services from Texas, U.S.A. These Terms and the transactions they contemplate,
        including without limitation their interpretation, construction, performance and enforcement, shall be governed
        by the laws of the State of Texas, U.S.A., including its statutes of limitations, but without reference to
        conflict or choice of law provisions. The International Convention on the Sale of Goods, and other international
        treaties that are not mandatory with respect to contracts made and performed entirely in Texas shall not apply.
      </TermsText>
      <TermsBoldText>17. Notice</TermsBoldText>
      <TermsText>
        All notices, demands, or consents given by you under these Terms will be in writing and will be deemed given
        when delivered to Ghostball at the following contact: info@ghostball.com. Any notices to you may be made via
        either e-mail or postal mail to the address in Ghostball 's records or via posting on the Ghostball Services.
        You agree that any notices, agreements, disclosures or other communications that we send to you electronically
        will satisfy any legal communication requirements, including, but not limited to, that such communications be in
        writing.
      </TermsText>
      <TermsText>
        Persons with disabilities who need assistance accessing these Terms may contact us as provided for in this
        Section, and depending on your individual needs, we will grant reasonable requests to furnish these Terms in an
        alternative format.
      </TermsText>
      <TermsBoldText>18. Severability</TermsBoldText>
      <TermsText>
        If any term, clause or provision of these Terms is held invalid or unenforceable, then that term, clause or
        provision will be severable from these Terms and will not affect the validity or enforceability of any remaining
        part of that term, clause or provision, or any other term, clause or provision of these Terms.
      </TermsText>
      <TermsBoldText>19. Miscellaneous</TermsBoldText>
      <TermsText>
        The Ghostball Services are hosted in the United States of America. If you are located outside of the United
        States of America and you contact us, please be advised that any information you provide to us will be
        transferred to the United States of America and that by submitting information, you explicitly authorize such
        transfer. These Terms constitute the entire agreement between you and Ghostball relating to your access to and
        use of the Ghostball Services. These Terms, and any rights granted hereunder, may not be transferred or assigned
        by you without the prior written consent of Ghostball. No waiver of any provision of these Terms will constitute
        a waiver of such provision in any prior, concurrent or subsequent circumstance, and Ghostball 's failure to
        assert any right or provision under these Terms shall not constitute a waiver of such right or provision. Except
        as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not
        intended to confer third-party beneficiary rights upon any other person or entity.
      </TermsText>
      <TermsBoldText></TermsBoldText>
      <TermsText></TermsText>
    </>
  )
}
