import { Button } from '../../../shared/button'
import { homePath } from '../../../logic/paths'
import { useNavigate } from 'react-router-dom'
import { SVGIcon } from '../../../shared/helpers/styled'
import image404 from '../../../assets/images/404-image.svg'
import { withTheme } from 'styled-components'
import { Container, Main } from './style'

export const PageNotFound = withTheme((props: any) => {
  const navigate = useNavigate()
  const { theme } = props
  return (
    <Container>
      <Main>
        <SVGIcon height="425px" width="425px" src={image404} />
        <Button
          onClick={() => navigate(homePath)}
          btnType="filledButton"
          customBorder="none"
          shadowColor={theme.primary}
          rippleColor={theme.black}
          customBgColor={theme.primary}
          customColor={theme.black}
        >
          BACK TO HOME
        </Button>
      </Main>
    </Container>
  )
})
