import { Button } from '../../../shared/button'
import { SVGIcon } from '../../../shared/helpers/styled'
import { AvatarArea, ButtonsContainer, SVGElement } from '../style'
import openInNew from '../../../assets/images/open-in-new-icon.svg'
import openInNewBlack from '../../../assets/images/open-in-new-icon-black.svg'
import { WalletText } from '../../../shared/Typography'
import { useState } from 'react'

interface INFTAvatarProps {
  nftSvg: any
  layer?: any
  layerColor?: any
  layerColor2?: any
  isPreview?: boolean
  handleShowPreview?: any
  nftId?: any
}

export const NFTAvatarSection = (props: INFTAvatarProps) => {
  const { nftSvg, layer, layerColor = '', layerColor2, isPreview, handleShowPreview, nftId } = props
  const [isHovering, setIsHovering] = useState(false)
  const handlePreview = () => {
    if (handleShowPreview) {
      handleShowPreview()
    }
  }
  return (
    <AvatarArea isPreview={isPreview}>
      <SVGElement
        customCss={`
          #${layer} {
            color: ${layerColor};
          }
          #${layer}-secondColor {
            stop {
              stop-color: ${layerColor2}
            }
          }
        `}
        dangerouslySetInnerHTML={{ __html: nftSvg }}
      />
      {isPreview ? (
        <WalletText fontSizeM="20px" fWeight="700">
          Ghostball #{nftId}
        </WalletText>
      ) : (
        <ButtonsContainer>
          <Button btnType="tileButton" onClick={handlePreview}>
            Preview
          </Button>
          <Button
            btnType="tileButton"
            onMouseLeave={() => setIsHovering(false)}
            onMouseover={() => setIsHovering(true)}
          >
            {isHovering ? (
              <SVGIcon width="16px" height="16px" src={openInNewBlack} />
            ) : (
              <SVGIcon width="16px" height="16px" src={openInNew} />
            )}
            Opensea
          </Button>
        </ButtonsContainer>
      )}
    </AvatarArea>
  )
}
