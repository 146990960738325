import { withTheme, ThemeProps } from 'styled-components'
import {
  AboutBody,
  AboutLeft,
  AnchorInLine,
  Avatar1,
  Avatar2,
  AvatarContainer,
  Card,
  CardTextWrapper,
  CardWrapper,
  ContainerAbout,
  ContainerGuide,
  ContainerRoadMap,
  ContainerTail,
  CornerBox,
  DetailsContainer,
  DetailsContainerAbout,
  FlexContainer,
  FloatingTile,
  IconsCntr,
  ImageWrapper,
  LineLeft,
  LineRight,
  ProgressBar,
  ProgressContainer,
  RoadMapHead,
  TailTextWrapper,
  TailWrapper,
} from './style'
import { BigBolderText, BolderText, CardBodyText, CardHeadText, MarkingText, SmallText } from '../../shared/Typography'
import {
  ABOUT_TEXT,
  BUY_ETH_TEXT,
  CR_TEXT,
  DETAILS_ABOUT_TEXT1,
  DETAILS_ABOUT_TEXT2,
  DETAILS_BUY_ETH_TEXT,
  DOWNLOAD_METAMASK_TEXT,
  GUIDE_DETAILS_TEXT,
  GUIDE_DETAILS_STEP1,
  GUIDE_DETAILS_STEP2,
  GUIDE_DETAILS_STEP3,
  GUIDE_HEAD_TEXT,
  MINT_READY_TEXT,
  ROAD_MAP_DETAILS_TEXT,
  ROAD_MAP_HEAD_TEXT,
  GB_TWITTER_URL,
  GB_DISCORD_URL,
  GB_YOUTUBE_URL,
  GB_INSTAGRAM_URL,
  META_MASK_URL,
  COINBASE_URL,
  BUY_ETH_URL,
} from '../../shared/helpers/text'
import { Button } from '../../shared/button'
import { Circle, CircleCntr, FlexCol, FlexRow, SVGIcon, TextList } from '../../shared/helpers/styled'

import gunGhostSvg from '../../assets/images/gun-ghost-avatar.svg'
import ghostSvg from '../../assets/images/big-logo-black.svg'
import metamaskSvg from '../../assets/images/metamask-icon.svg'
import coinbaseSvg from '../../assets/images/coinbase-icon.svg'
import ethereumSvg from '../../assets/images/ethereum-icon.svg'
import targetIcon from '../../assets/images/target-icon.svg'
import roadmapImg from '../../assets/images/roadmap-Img.png'
import instaIcon from '../../assets/images/insta-icon.svg'
import discordIcon from '../../assets/images/discord-icon.svg'
import twitterIcon from '../../assets/images/twitter-icon.svg'
import youtubeIcon from '../../assets/images/youtube-icon.svg'

import { useIsMobileScreen } from '../../shared/hooks/useIsMobileScreen'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { Team } from './components/Team'
import { goToElement } from '../../shared/helpers/util'
import { web3 } from '../../wallet_helpers/useAuth'
import { setConnectWallet } from '../../logic/redux/actions'
import { LinkIcon } from './components/mint/style'
import { AnchorText } from '../dashboard/style'
import { FAQSection } from './components/FAQ'
import { privacyPolicyPath, termsPath } from '../../logic/paths'
import { Row } from '../app/navbar/style'

export const Home: React.FC = withTheme((props: ThemeProps<any>) => {
  const { theme } = props
  const { account }: any = useWeb3React()

  const globalSelector = useSelector((state: any) => state)
  const { isMintingOver } = globalSelector.navbar
  useEffect(() => {
    const pathArr = window.location.href.split('/')
    pathArr.some((el) => {
      if (el.includes('#')) {
        goToElement(el)
      }
    })
  }, [])
  return (
    <>
      {isMintingOver && account && <AboutArea theme={theme} />}
      <GuideArea theme={theme} />
      <RoadMap theme={theme} />
      <Team theme={theme} />
      <FAQSection theme={theme} />
      <Tail theme={theme} />
    </>
  )
})

const AboutArea = (props: any) => {
  const { theme } = props
  return (
    <ContainerAbout id="#about">
      <FlexContainer>
        <AvatarContainer>
          <Avatar1 src={gunGhostSvg} alt="gun-ghost" />
          <FloatingTile>0/10000</FloatingTile>
        </AvatarContainer>
        <DetailsContainerAbout>
          <FlexCol padding="0 20px" gap="16px">
            <BolderText fColor={theme.white}>{ABOUT_TEXT}</BolderText>
            <SmallText fontSizeM="14px" fColor={theme.white}>
              {DETAILS_ABOUT_TEXT1}
            </SmallText>
            <SmallText fontSizeM="14px" fColor={theme.white}>
              {DETAILS_ABOUT_TEXT2}
            </SmallText>
          </FlexCol>
          <Button
            customBorder="none"
            shadowColor={theme.primary}
            rippleColor={theme.black}
            customBgColor={theme.primary}
            customColor={theme.black}
            btnType="filledButton"
          >
            MINT NOW
          </Button>
        </DetailsContainerAbout>
      </FlexContainer>
    </ContainerAbout>
  )
}
const GuideArea = (props: any) => {
  const { theme } = props
  const { account, library } = useWeb3React()

  const progressRef: any = useRef()
  const isMobile = useIsMobileScreen()
  const [scrollProgress, setScrollProgress] = useState(0)

  useEffect(() => {
    if (library) {
      init()
    } else {
      setScrollProgress(0)
    }
  }, [account, library])
  const init = async () => {
    if (account) {
      const userWeiBal = await web3.eth.getBalance(account)
      const userEthBal = web3.utils.fromWei(userWeiBal, 'ether')
      if (Number(userEthBal) > 0) {
        setScrollProgress(75)
      } else {
        setScrollProgress(45)
      }
    }
  }

  return (
    <ContainerGuide>
      <FlexContainer>
        <AvatarContainer>
          <Avatar2 src={ghostSvg} alt="cap-ghost" />
          <FlexCol padding="0 20px" gap="16px">
            <BolderText fColor={theme.black}>{GUIDE_HEAD_TEXT}</BolderText>
            <SmallText fontSizeM="16px" fColor={theme.black}>
              {GUIDE_DETAILS_TEXT}
            </SmallText>
            <TextList>
              <SmallText fontSizeM="16px" fColor={theme.black}>
                {GUIDE_DETAILS_STEP1}
              </SmallText>
              <SmallText fontSizeM="16px" fColor={theme.black}>
                {GUIDE_DETAILS_STEP2}
              </SmallText>
              <SmallText fontSizeM="16px" fColor={theme.black}>
                {GUIDE_DETAILS_STEP3}
              </SmallText>
            </TextList>
          </FlexCol>
        </AvatarContainer>
        <DetailsContainer>
          <ProgressContainer ref={progressRef}>
            <ProgressBar height={scrollProgress} />
          </ProgressContainer>
          <MetamaskGuide account={account} scrollProgress={scrollProgress} theme={theme} />
          <EthereumGuide scrollProgress={scrollProgress} theme={theme} />
          <MintGuide account={account} scrollProgress={scrollProgress} theme={theme} />
        </DetailsContainer>
      </FlexContainer>
    </ContainerGuide>
  )
}
const MetamaskGuide = (props: any) => {
  const { theme, scrollProgress, account = null } = props
  const dispatch = useDispatch()

  const handleConnectwallet = (p: boolean) => {
    dispatch(setConnectWallet(p))
    document.body.style.overflow = 'hidden'
  }
  return (
    <AboutBody>
      <AboutLeft>
        <CircleCntr>
          <Circle isActive={scrollProgress > 0}>
            <MarkingText fWeight="500">1</MarkingText>
          </Circle>
        </CircleCntr>
      </AboutLeft>
      <FlexCol padding="15px 0 0 0" gap="30px">
        <FlexCol gap="12px">
          <FlexRow gap="12px">
            <SVGIcon src={metamaskSvg} alt="metamask-icon" />
            <SVGIcon src={coinbaseSvg} alt="coinbase-icon" />
          </FlexRow>
          <FlexCol maxwidthM="280px" gap="16px">
            <BolderText fColor={theme.black}>{DOWNLOAD_METAMASK_TEXT}</BolderText>
            <SmallText fColor={theme.black}>
              Download and install your wallet of choice. Options include{' '}
              <AnchorText href={META_MASK_URL} target="_blank" rel="noopener noreferrer">
                MetaMask
              </AnchorText>
              ,{' '}
              <AnchorText href={COINBASE_URL} target="_blank" rel="noopener noreferrer">
                Coinbase
              </AnchorText>{' '}
              and hardware wallets. You can then authorize websites to access your Ethereum wallet.
            </SmallText>
          </FlexCol>
        </FlexCol>
        {!account && (
          <Button
            justify="start"
            rippleColor={theme.black}
            customBgColor={theme.primary}
            customColor={theme.black}
            btnType="filledButton"
            customWidth="190px"
            onClick={() => handleConnectwallet(true)}
          >
            LINK METAMASK
          </Button>
        )}
      </FlexCol>
    </AboutBody>
  )
}

const EthereumGuide = (props: any) => {
  const { theme, scrollProgress } = props

  return (
    <AboutBody>
      <AboutLeft>
        <CircleCntr>
          <Circle isActive={scrollProgress > 49}>
            <MarkingText fWeight="500">2</MarkingText>
          </Circle>
        </CircleCntr>
      </AboutLeft>
      <FlexCol padding="15px 0 0 0" gap="30px">
        <FlexCol gap="12px">
          <SVGIcon src={ethereumSvg} alt="metamask-icon" />
          <FlexCol maxwidthM="280px" gap="16px">
            <BolderText fColor={theme.black}>{BUY_ETH_TEXT}</BolderText>
            <SmallText fColor={theme.black}>
              {DETAILS_BUY_ETH_TEXT}
              <AnchorText href={BUY_ETH_URL} target="_blank" rel="noopener noreferrer">
                beginner's guide to buying Ethereum.
              </AnchorText>
            </SmallText>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </AboutBody>
  )
}

const MintGuide = (props: any) => {
  const { theme, scrollProgress, account = null } = props
  const [isMintedStart, setIsMintedStart] = useState<boolean>(false)

  return (
    <AboutBody>
      <AboutLeft zIndex="1" bg>
        <CircleCntr>
          <Circle isActive={67 < scrollProgress}>
            <MarkingText fWeight="500">3</MarkingText>
          </Circle>
        </CircleCntr>
      </AboutLeft>
      <FlexCol padding="15px 0 0 0" gap="30px">
        <FlexCol gap="12px">
          <SVGIcon height="60px" width="60px" src={ghostSvg} alt="metamask-icon" />
          <FlexCol maxwidthM="280px" gap="16px">
            <BolderText fColor={theme.black}>{MINT_READY_TEXT}</BolderText>
            <SmallText fColor={theme.black}>
              When mint date arrives, use the mint button to secure your Ghostball NFT! Join our{' '}
              <AnchorText href={GB_TWITTER_URL} target="_blank" rel="noopener noreferrer">
                Twitter{' '}
              </AnchorText>{' '}
              and{' '}
              <AnchorText href={GB_DISCORD_URL} target="_blank" rel="noopener noreferrer">
                Discord
              </AnchorText>{' '}
              for updates.
            </SmallText>
          </FlexCol>
        </FlexCol>
        {account && isMintedStart && scrollProgress === 75 && (
          <Button
            justify="start"
            rippleColor={theme.black}
            customBgColor={theme.primary}
            customColor={theme.black}
            btnType="filledButton"
            customWidth="190px"
            onClick={() => goToElement('#mint')}
          >
            MINT GHOSTBALL
          </Button>
        )}
      </FlexCol>
    </AboutBody>
  )
}

const RoadMap = (props: any) => {
  const { theme } = props
  const isMobileScreen = useIsMobileScreen()
  return (
    <ContainerRoadMap id="#road-map">
      <RoadMapHead>
        <BigBolderText fontSizeM="36px" fLineHeightM="40px" fColor={theme.white}>
          {ROAD_MAP_HEAD_TEXT}
        </BigBolderText>
        <SmallText fontSizeM="14px" fColor={theme.white}>
          {ROAD_MAP_DETAILS_TEXT}
        </SmallText>
      </RoadMapHead>
      <SVGIcon src={roadmapImg} height={'auto'} width="100%" />
    </ContainerRoadMap>
  )
}
interface ICardProps {
  leftLine?: boolean
  rightLine?: boolean
  corner?: boolean
  year?: string
  headText: string
  bodyText: string
}
const RoadMapCard = (props: ICardProps) => {
  const { leftLine, rightLine, corner, year, headText, bodyText } = props
  return (
    <CardWrapper>
      <BigBolderText tAlign="center" fColor="#ffffff" fSize="24px">
        {year}
      </BigBolderText>
      <ImageWrapper>
        {leftLine && <LineLeft />}
        <SVGIcon src={targetIcon} alt="target-icon" />
        {rightLine && <LineRight />}
        {corner && <CornerBox />}
      </ImageWrapper>

      <Card>
        <CardTextWrapper>
          <CardHeadText>{headText}</CardHeadText>
          <CardBodyText fontSizeM="14px">{bodyText}</CardBodyText>
        </CardTextWrapper>
      </Card>
    </CardWrapper>
  )
}

const Tail = (props: any) => {
  const { theme } = props
  return (
    <ContainerTail>
      <TailWrapper>
        <TailTextWrapper>
          <SmallText fColor={theme.white}>{CR_TEXT}</SmallText>
          <Row>
            <AnchorInLine href={privacyPolicyPath} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </AnchorInLine>
            <AnchorInLine href={termsPath} target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </AnchorInLine>
          </Row>
        </TailTextWrapper>
        <IconsCntr>
          <LinkIcon href={GB_TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={twitterIcon} alt="twitter-icon" />
          </LinkIcon>
          <LinkIcon href={GB_DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={discordIcon} alt="discord-icon" />
          </LinkIcon>
          <LinkIcon href={GB_YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={youtubeIcon} alt="youtube-icon" />
          </LinkIcon>
          <LinkIcon href={GB_INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={instaIcon} alt="insta-icon" />
          </LinkIcon>
        </IconsCntr>
      </TailWrapper>
    </ContainerTail>
  )
}
