import { withTheme } from 'styled-components'
import { TermsBoldText, TermsText, TermsText2 } from '../../../shared/Typography'
import { AnchorInLine } from '../../home/style'
import { CardBodyTextCntr } from './style'
import { Container, Main, TblContainer } from './style'

export const PrivacyPolicy = withTheme((props: any) => {
  return (
    <Container>
      <Main>
        <CardBodyTextCntr>
          <TermsBoldText>1. Acceptance of Privacy Policy</TermsBoldText>
          <TermsText>
            Welcome to Ghostball, LLC (“Ghostball” “we” or “us”) and our Privacy Policy (“Privacy Policy”). This Privacy
            Policy is important and affects your legal rights, so please read carefully.
          </TermsText>
          <TermsText>
            By accessing or using{' '}
            <AnchorInLine href="https://www.ghostballnft.com/" target="_blank" rel="noopener noreferrer">
              ghostballnft.com
            </AnchorInLine>{' '}
            and various related websites and services (collectively, the “Ghostball Services”), you agree to be bound by
            this Privacy Policy and all of the terms incorporated herein by reference. By using the Ghostball Services
            and/or submitting or collecting any personal data via the Ghostball Services, you accept and expressly
            consent and agree to our practices surrounding the collection, use, and sharing of your personal information
            in accordance with this Privacy Policy. IF YOU DO NOT CONSENT AND AGREE WITH THE TERMS OF THIS PRIVACY
            POLICY, YOU CANNOT, AND WE DO NOT AUTHORIZE YOU TO, ACCESS, BROWSE, OR USE THE GHOSTBALL SERVICES.
          </TermsText>
          <TermsText>
            Our processing of personal information, such as your name, address, e-mail address, or telephone number,
            will be undertaken consistent with the requirements of applicable privacy laws, including, but not limited
            to, the California Consumer Privacy Act (“CCPA”), the General Data Protection Regulation (“GDPR”), the UK
            General Data Protection Regulation (“UK GDPR”), and the Canadian Personal Information Protection and
            Electronic Documents Act (“PIPEDA”).
          </TermsText>

          <TermsBoldText>2. Information We Collect</TermsBoldText>
          <TermsBoldText>2.1 Personal Information We Collect Directly from You</TermsBoldText>
          <TermsText>
            We receive personal information as described to you at the point of collection, pursuant to your consent,
            and/or when you voluntarily provide us with personal information, including:
          </TermsText>
          <TermsText2>(1) individual information (such as your e-mail address and phone number);</TermsText2>
          <TermsText2>(2) company information (such as your company's address); and</TermsText2>
          <TermsText2>
            (3) other identifying information that you voluntarily choose to provide to us, including without limitation
            unique identifiers such as passwords, and personal information in messages you send to us.
          </TermsText2>
          <TermsText>
            We may also collect additional information, which may be personal information, as otherwise described to you
            at the point of collection or pursuant to your consent.
          </TermsText>

          <TermsBoldText>2.2 Information We Automatically Collect When You Use Our Site</TermsBoldText>
          <TermsText>
            In order to access and use certain areas or features of the Ghostball Services, you consent to our
            collection and use of certain information about your use of the Ghostball Services through the use of
            tracking technologies or by other passive means. Your consent to our access and use of this “passively
            collected” information includes, but is not limited to, the domain name of the website that allowed you to
            navigate to the Ghostball Services, search engines used, the internet protocol (IP) address used, the length
            of time spent on the Ghostball Services, the pages you looked at on the Ghostball Services, other webpages
            you visited before and after visiting the Ghostball Services, the type of device and/or internet browser you
            have, the frequency of your visits to the Ghostball Services, and other relevant statistics (collectively
            “Traffic Data”):
          </TermsText>
          <TermsBoldText>3. How We Collect Information</TermsBoldText>
          <TermsText>
            We collect information (including personal information and Traffic Data) when you use and interact with the
            Ghostball Services, and in some cases from third party sources. Such means of collection include:
          </TermsText>
          <ul>
            <li>
              <TermsText2>When you access, use, or contact us through the Ghostball Services.</TermsText2>
            </li>
            <li>
              <TermsText2>When you voluntarily provide information through the Ghostball Services.</TermsText2>
            </li>
            <li>
              <TermsText2>
                If you use a location-enabled browser, we may receive information about your location and device.
              </TermsText2>
            </li>
            <li>
              <TermsText2>
                Through Cookies, Web Beacons, analytics services and other tracking technologies (collectively,
                “Tracking Tools”).
              </TermsText2>
            </li>
          </ul>

          <TermsBoldText>4. Tracking Tools, Behavioral Advertising, and Opt Out Options</TermsBoldText>
          <TermsBoldText>4.1. Tracking Tools</TermsBoldText>
          <TermsText>
            We may use tools outlined below in order to provide the Ghostball Services, advertise to, and better
            understand users.
          </TermsText>
          <ul>
            <li>
              <TermsText2>
                <b>Cookies:</b> “Cookies” are small computer files transferred to your device that contain information
                such as user ID, user preferences, lists of pages visited and activities conducted while using the
                Ghostball Services. We use Cookies to (i) improve and tailor the Ghostball Services, (ii) customize
                advertisements, (iii) measure performance (iv) store authentication so re-entering credentials is not
                required, (v) customize user experiences, and for (vi) analytics and fraud prevention. For more
                information on Cookies, including how to control your Cookie settings and preferences, visit{' '}
                <AnchorInLine href="http://www.allaboutCookies.org" target="_blank" rel="noopener noreferrer">
                  http://www.allaboutCookies.org
                </AnchorInLine>
                . You can also manage Cookies in your web browser (for example, Edge, Explorer, Chrome, Safari). If you
                choose to change your settings, you may find that certain functions or features of the Ghostball
                Services will not work as intended. The follow details the types of Cookies we use and why we use them:
              </TermsText2>
              <ul>
                <li>
                  <TermsText>
                    Absolutely necessary Cookies. These Cookies are essential to enable you to move around a website and
                    use its features. Without these Cookies, services you have asked for, like adding items to an online
                    order, cannot be provided.
                  </TermsText>
                </li>
                <li>
                  <TermsText>
                    Absolutely necessary Cookies. These Cookies are essential to enable you to move around a website and
                    use its features. Without these Cookies, services you have asked for, like adding items to an online
                    order, cannot be provided.
                  </TermsText>
                </li>
                <li>
                  <TermsText>
                    Functionality Cookies. These Cookies allow the Ghostball Services to remember choices you make (such
                    as your username, language preference, or the area or region you are in) and provide enhanced, more
                    personal features. These Cookies can also be used to remember changes you have made to text size,
                    fonts, and other customizable parts of the Ghostball Services. The information these Cookies collect
                    may be anonymized, and they cannot track your browsing activity on other websites.
                  </TermsText>
                </li>
              </ul>
            </li>
            <li>
              <TermsText2>
                <b>Web Beacons:</b> “Web Beacons” (a.k.a. clear GIFs or pixel tags) are tiny graphic image files
                embedded in a web page or email that may be used to collect information about the use of the Ghostball
                Services. The information collected by Web Beacons allows us to analyze how many people are using the
                Ghostball Services, using selected publishers' websites or opening emails, and for what purpose.
              </TermsText2>
            </li>
            <li>
              <TermsText2>
                <b>Web Service Analytics:</b> We may use third-party analytics services in connection with the Ghostball
                Services, including, for example, to register mouse clicks, mouse movements, scrolling activity and text
                typed into the Ghostball Services. We use the information collected from these services to help make the
                Ghostball Services easier to use and as otherwise set forth in Section 5 (How We Use Your Information).
                These analytics services generally do not collect personal information unless you voluntarily provide
                it.
              </TermsText2>
            </li>
            <li>
              <TermsText2>
                <b>Mobile Device Identifiers:</b> As with other Tracking Tools, mobile device identifiers help Ghostball
                learn more about our users' demographics and Internet behaviors in order to personalize and improve the
                Ghostball Services. Mobile device identifiers are data stored on mobile devices that may track
                activities occurring on and through it, as well as the applications installed on it. Mobile device
                identifiers enable collection of personal information (such as media access control, address and
                location) and Traffic Data.
              </TermsText2>
            </li>
          </ul>

          <TermsBoldText>4.2. Behavioral Advertising</TermsBoldText>
          <TermsText>
            We may use a type of advertising commonly known as interest-based or online behavioral advertising. This
            means that some of our partners use Tracking Tools to collect information about a user's online activities
            to display Ghostball ads to the user based on the user's interests (“Behavioral Advertising”). Our partners
            may include third-party advertisers and other third party service providers, and such partners may collect
            information when you use the Ghostball Services, such as IP address, mobile device ID, operating system, and
            demographic information. These Tracking Tools help Ghostball learn more about our users' demographics and
            Internet behaviors.
          </TermsText>
          <TermsBoldText>4.3. Options for Opting out of Cookies and Mobile Device Identifiers</TermsBoldText>
          <TermsText>
            If we process Cookies based on your consent (as indicated at the time of collection of such data), you have
            the right to withdraw your consent at any point in time by contacting us at info@ghostball.com. Please note,
            if you exercise this right, you may have to provide your consent on a case-by-case basis to enable you to
            utilize some or all of the Ghostball Services.
          </TermsText>
          <TermsText>
            You may be able to reject Cookies and/or mobile device identifiers by activating the appropriate setting on
            your browser or device. Although you are not required to accept Ghostball's Cookies or mobile device
            identifiers, if you block or reject them, you may not have access to all features available through the
            Ghostball Services.
          </TermsText>
          <ul>
            <li>
              <TermsText>
                You may opt out of receiving certain Cookies by visiting the Network Advertising Initiative (NAI) opt
                out page or the Digital Advertising Alliance (DAA) opt out page, or by installing the DAA's AppChoice
                app (for iOS; for{' '}
                <AnchorInLine
                  href="https://play.google.com/store/apps/details?id=com.DAA.appchoices&amp;hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </AnchorInLine>{' '}
                ) on your mobile device. When you use these opt-out features, an “opt-out” Cookie will be placed on your
                device indicating that you do not want to receive interest-based advertising from NAI or DAA member
                companies. If you delete Cookies on your device, you may need to opt out again. For information about
                how to opt out of interest-based advertising on mobile devices, please visit{' '}
                <AnchorInLine
                  href="http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device
                </AnchorInLine>
                . You will need to opt out of each browser and device for which you desire to apply these opt-out
                features.
              </TermsText>
            </li>
            <li>
              <TermsText>
                Even after opting out of Behavioral Advertising, you may still see Ghostball advertisements that are not
                interest-based (i.e., not targeted toward you). Also, opting out does not mean that Ghostball is no
                longer using Tracking Tools. Ghostball still may collect information about your use of the Ghostball
                Services even after you have opted out of Behavioral Advertising and may still serve advertisements to
                you via the Ghostball Services based on information it collects through the Ghostball Services.
              </TermsText>
            </li>
          </ul>
          <TermsText>
            This Privacy Policy does not cover the use of Cookies and other Tracking Tools by any third parties, and we
            are not responsible for the privacy practices of any third party. Please be aware that some third party
            Cookies can continue to track your activities online even after you have left the Ghostball Services.
          </TermsText>
          <TermsBoldText>4.4. How Ghostball Responds to Browser “Do Not Track” (DNT) Signals</TermsBoldText>
          <TermsText>
            Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) incorporate a “Do Not Track”
            (DNT) or similar feature that signals to web services that a visitor does not want to have their online
            activity and behavior tracked. If a web service operator elects to respond to a particular DNT signal, the
            web service operator may refrain from collecting certain personal information about the browser's user. Not
            all browsers offer a DNT option and there is currently no industry consensus as to what constitutes a DNT
            signal. For these reasons, many web service operators, including Ghostball, do not proactively respond to
            DNT signals. For more information about DNT signals, visit{' '}
            <AnchorInLine href="http://allaboutdnt.com" target="_blank" rel="noopener noreferrer">
              http://allaboutdnt.com
            </AnchorInLine>
            .
          </TermsText>
          <TermsBoldText>5. How We Use Your Information</TermsBoldText>
          <TermsText>
            We do not engage in automated decision making. We may use information (including information that has been
            de-identified and/or aggregated) to better understand who uses the Ghostball Services and how we can deliver
            a better user experience. We use information, including personal information, to provide the Ghostball
            Services and to help improve the Ghostball Services, to develop new services, and to advertise our services.
            Specifically, such use may include:
          </TermsText>
          <ul>
            <li>
              <TermsText>Providing you with the products, services and information you request.</TermsText>
            </li>
            <li>
              <TermsText>Corresponding with you.</TermsText>
            </li>
            <li>
              <TermsText>
                Providing, maintaining, administering or expanding the Ghostball Services, performing business analyses,
                and for other internal purposes.
              </TermsText>
            </li>
            <li>
              <TermsText>
                Combining information received from third parties with information that we have from or about you and
                using the combined information for any of the purposes described in this Privacy Policy.
              </TermsText>
            </li>
            <li>
              <TermsText>
                Showing you advertisements, including interest-based or online behavioral advertising.
              </TermsText>
            </li>
            <li>
              <TermsText>
                Fulfilling our legal obligations, such as preventing, detecting and investigating security incidents,
                fraud, and potentially illegal or prohibited activities.
              </TermsText>
            </li>
            <li>
              <TermsText>Enforcing our Privacy Policy and other agreements.</TermsText>
            </li>
          </ul>
          <TermsText>
            By providing your e-mail and/or phone number and checking a box, clicking the “complete” button, or some
            other affirmative act, you are consenting to receive e-mails and/or calls and text messages, including live,
            prerecorded, and/or automated calls and messages, to that email or phone number. After signing up, you will
            receive a confirmation e-mail or text message from Ghostball on your mobile number. This agreement is not
            entered into as a term or requirement of any purchase or promotion. Normal message and data rates may apply.
            Message frequency may vary. Neither we nor the participating carriers guarantee that messages will be
            delivered. We may discontinue these programs at any time without notice.
          </TermsText>
          <TermsBoldText>6. How We Share Your Information</TermsBoldText>
          <TermsText>
            In certain circumstances, and in order to perform the Ghostball Services, we may share certain information
            about you as described in this section:
          </TermsText>
          <ul>
            <li>
              <TermsText>We do not sell your personal information to third parties.</TermsText>
            </li>
            <li>
              <TermsText>
                We may share your personal information with our partners to customize or display our advertising.
              </TermsText>
            </li>
            <li>
              <TermsText>
                We may share your personal information and/or Traffic Data with our partners who perform operational
                services for us (such as hosting, billing, fulfillment, data storage, security, insurance verification,
                web service analytics, or ad serving).
              </TermsText>
            </li>
            <li>
              <TermsText>
                We may share your personal information and/or Traffic Data with our partners to analyze device
                activities and generate targeted advertisements.
              </TermsText>
            </li>
            <li>
              <TermsText>
                We may transfer your personal information to another company in connection with a proposed merger, sale,
                acquisition or other change of ownership or control by or of Ghostball (whether in whole or in part). We
                will make reasonable efforts to notify you before your information becomes subject to different privacy
                practices.
              </TermsText>
            </li>

            <li>
              <TermsText>
                We may transfer your personal information to another company in connection with a proposed merger, sale,
                acquisition or other change of ownership or control by or of Ghostball (whether in whole or in part). We
                will make reasonable efforts to notify you before your information becomes subject to different privacy
                practices.
              </TermsText>
            </li>
            <li>
              <TermsText>
                We also may need to disclose your personal information or any other information we collect about you if
                we determine in good faith that such disclosure is needed to: (1) comply with or fulfill our obligations
                under applicable law; (2) protect the rights, property or safety of you, Ghostball or another party; (3)
                enforce this Privacy Policy or other agreements with you; or (4) respond to claims that any posting or
                other content violates third-party rights.
              </TermsText>
            </li>
          </ul>
          <TermsBoldText>7. Storage and Security of Information</TermsBoldText>
          <TermsText>
            Any information you send to us electronically, while using the Ghostball Services or otherwise interacting
            with us, may not be secure when it is transmitted to us. We recommend that you do not use unsecure channels
            to communicate sensitive or confidential information to us. Please be aware, despite our best efforts, no
            security measures are perfect or impenetrable, and we cannot guarantee “perfect security.” Any information
            you send us through any means is transmitted at your own risk.
          </TermsText>
          <TermsBoldText>8. Your Choices</TermsBoldText>
          <TermsBoldText>8.1 Information You Provide</TermsBoldText>
          <TermsText>
            You can choose whether or not to provide personal information through the Ghostball Services. We will not
            discriminate against you for exercising any of your rights relating to your personal information and will
            not (i) deny you goods or services, (ii) provide you with a different level or quality of services, or (iii)
            charge you different prices for services for doing so.
          </TermsText>
          <TermsBoldText>8.2 California Privacy Rights</TermsBoldText>
          <TermsText>
            In the preceding 12 months, we collected and disclosed for a business purpose the following categories of
            personal information about California consumers:
          </TermsText>
        </CardBodyTextCntr>
        <TableData />
        <CardBodyTextCntr>
          <TermsText>
            <b>Purposes for Collection, Use, and Sharing.</b> We use and disclose the personal information we collect
            for our commercial purposes, as further described in this Privacy Policy, including for our business
            purposes with our partners and service providers as follows:
          </TermsText>
          <ul>
            <li>
              <TermsText>Legal compliance and auditing related to our interactions with you.</TermsText>
            </li>
            <li>
              <TermsText>
                Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity,
                and exercising our related rights.
              </TermsText>
            </li>
            <li>
              <TermsText>Performing and improving out services (by us or our service provider(s)).</TermsText>
            </li>
            <li>
              <TermsText>Internal operations.</TermsText>
            </li>
            <li>
              <TermsText>Other one-time or short-term uses.</TermsText>
            </li>
          </ul>
          <TermsText>
            <b>Your Rights</b>. If you are a California resident you may have the following rights under the CCPA in
            relation to personal information we have collected about you, to the extent required by the CCPA and subject
            to verification:
          </TermsText>
          <TermsText>
            Right to Know/Access: The right to request certain information about our collection and use of personal
            information about you as described below:
          </TermsText>
          <ul>
            <li>
              <TermsText>The specific pieces of your personal information collected.</TermsText>
            </li>
            <li>
              <TermsText>The categories of your personal information collected.</TermsText>
            </li>
            <li>
              <TermsText>The categories of sources from whom your personal information is collected.</TermsText>
            </li>
            <li>
              <TermsText>The purpose for collecting your personal information.</TermsText>
            </li>
            <li>
              <TermsText>The categories of third parties with whom we have shared your personal information.</TermsText>
            </li>
          </ul>
          <TermsText>
            Right to Delete: The right to request that we delete certain personal information we have about you.
          </TermsText>
          <TermsText>
            Freedom from Discrimination: You have the right to be free from unlawful discrimination for exercising any
            of the rights above.
          </TermsText>
          <TermsText>
            To make a request in relation to the above rights, please contact us using the information in Section 13
            below. To fulfil your request, we will need to verify your identity. Only you or someone legally authorized
            to act on your behalf may make a request related to your personal information. To designate an authorized
            agent, the authorized agent must provide sufficient information that allows us to reasonably verify that you
            authorize them to act on your behalf.
          </TermsText>
          <TermsBoldText>8.3 Canada, European Union and United Kingdom Privacy Rights</TermsBoldText>
          <TermsText>
            If our processing of your personal information is subject to GDPR, UKGDPR, and/or PIPEDA, and unless subject
            to an exemption, you may have the following rights with respect to your personal information:
          </TermsText>
          <ul>
            <li>
              <TermsText>
                To request a copy of the personal information we hold about you and where possible, to transmit that
                data directly to another data controller.
              </TermsText>
            </li>
            <li>
              <TermsText>To request we correct any personal information about you.</TermsText>
            </li>
            <li>
              <TermsText>
                To request your personal information be erased when no longer necessary for us to retain such data;
              </TermsText>
            </li>
            <li>
              <TermsText>To request a copy of the personal information we hold about you.</TermsText>
            </li>
            <li>
              <TermsText>
                Where there is a dispute in relation to the accuracy or processing of your personal information, to
                request a restriction be placed on further processing.
              </TermsText>
            </li>
            <li>
              <TermsText>To object to the processing of personal information (where applicable).</TermsText>
            </li>
            <li>
              <TermsText>To lodge a complaint with a data supervisory authority.</TermsText>
            </li>
          </ul>
          <TermsText>
            We will need to verify your identity to process any requests described in this Section and may deny your
            request if we are unable to verify your identity. Government or other identification may be required.
          </TermsText>
          <TermsText>
            If you are a resident of the European Economic Area (“EEA”), when we process your personal information, we
            will only do so in the following situations:
          </TermsText>
          <ul>
            <li>
              <TermsText>
                We need to use your information to perform our responsibilities under our agreement with you.
              </TermsText>
            </li>
            <li>
              <TermsText>
                We have a legitimate interest in processing your personal information. For example, we may process your
                personal information to send you marketing communications, to communicate with you about the Ghostball
                Services, and to provide and improve the Ghostball Services.
              </TermsText>
            </li>
            <li>
              <TermsText>We have your consent to do so.</TermsText>
            </li>
          </ul>
          <TermsText>
            If your personal data is subject to GDPR or UK GDPR, we will transfer personal data from the EEA to a
            location outside the EEA only when there has been a documented adequacy determination, or where we have
            confirmed adequate privacy protections. If your personal data is subject to PIPEDA, we will transfer
            personal data from Canada to locations outside Canada only where we have confirmed adequate privacy
            protections. If we transfer personal data to a third party acting as our agent, we will also obligate the
            third party to have adequate privacy protections in place.
          </TermsText>
          <TermsBoldText>9. How Long We Retain Your Information</TermsBoldText>
          <TermsText>
            We retain personal information about you for as long as necessary to provide you the Ghostball Services. In
            some cases, we retain personal information for longer, if doing so is necessary to comply with our legal
            obligations, or as otherwise permitted by applicable law. Afterwards, we retain some information in a de-
            identified and/or aggregated form but not in a way that would identify you personally.
          </TermsText>

          <TermsBoldText>10. Information Provided on Behalf of Children and Others</TermsBoldText>
          <TermsText>
            The Ghostball Services are not intended for use by children. Individuals under the age of 18 may not use the
            Ghostball Services. Ghostball does not knowingly collect any information from children. If you are under 18,
            do not attempt to register for the Ghostball Services or send us any personal information. By accessing,
            using and/or submitting information through the Ghostball Services, you represent that you are not younger
            than 18 and that you have authority to do so. If you are a parent or legal guardian of a minor child, you
            may, in compliance with this Privacy Policy, use the Ghostball Services on behalf of such minor child.
            Information you provide through the Ghostball Services on behalf of a minor child will be treated as
            personal information as provided herein. If you use the Ghostball Services on behalf of another person,
            regardless of age, you represent and warrant that you have authority to do so.
          </TermsText>

          <TermsBoldText>11. Third Party Web Services</TermsBoldText>
          <TermsText>
            The Ghostball Services may contain links or content from third party websites. A link or content from a
            third party website does not mean we endorse that website, the accuracy of information presented, or the
            persons or entities associated with that website. If you visit a third party website, you are subject to the
            privacy policy of the applicable third party and we are not responsible for the policies and/or practices of
            any third party. We encourage you to ask questions before you disclose your information to others.
          </TermsText>

          <TermsBoldText>12. Updates and Changes to Privacy Policy</TermsBoldText>
          <TermsText>
            The effective date of this Privacy Policy is set forth at the top of this webpage. We will notify you of any
            material change by posting notice on this webpage. Your continued use of the Ghostball Services after the
            effective date of any amendment to this Privacy Policy constitutes your acceptance of the amended Privacy
            Policy. We encourage you to periodically review this page for the latest information on our privacy
            practices. Any amended Privacy Policy supersedes all previous versions. IF YOU DO NOT AGREE TO FUTURE
            CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP USING THE GHOSTBALL SERVICES AFTER THE EFFECTIVE DATE OF SUCH
            CHANGES.
          </TermsText>

          <TermsBoldText>13. Contact Us</TermsBoldText>
          <TermsText>
            The data controller for the purposes of GDPR, UK GDPR, PIPEDA or other data protection laws is Ghostball. If
            you have any questions about this Privacy Policy, please contact us at info@ghostball.com
          </TermsText>
          <TermsText>or at:</TermsText>
          <TermsText>Ghostball, LLC</TermsText>
          <TermsText>Attn:</TermsText>
          <TermsText></TermsText>
          <TermsText></TermsText>
          <TermsText></TermsText>
          <TermsText></TermsText>
        </CardBodyTextCntr>
      </Main>
    </Container>
  )
})

const TableData = () => {
  return (
    <TblContainer>
      <table>
        <tbody>
          <tr>
            <td>
              <strong>Category</strong>
            </td>
            <td>
              <div>
                <strong>Examples</strong>
              </div>
            </td>
            <td>
              <strong>Collected?</strong>
            </td>
            <td>
              <div>
                <strong>
                  Categories of Recipients
                  <br />
                </strong>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Identifiers</div>
            </td>
            <td>
              <p>Name, address, e-mail</p>
              <p>address, IP address</p>
            </td>
            <td>
              <div>Yes</div>
            </td>
            <td>
              <p>Organizations providing </p>
              <p>services to Ghostball</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Personal information </p>
              <p>categories listed</p>
              <p> in the California </p>
              <p> Customer Records statute</p>
            </td>
            <td>
              <p>Name, professional-</p>
              <p>related information;</p>
              <p>and employment-related</p>
              <p>information</p>
            </td>
            <td>
              <div>Yes</div>
            </td>
            <td>
              <p>Organizations providing services</p>
              <p>to Ghostball</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Protected classification</p>
              <p>characteristics under</p>
              <p>California or federal law</p>
            </td>
            <td>
              <p>Name, age, citizenship,</p>
              <p>nationality, ancestry,</p>
              <p>religion or creed,</p>
              <p>marital status, medical</p>
              <p>condition, physical or</p>
              <p>mental disability, sex</p>
              <p>(including gender,</p>
              <p>gender identity, gender</p>
              <p>expression, pregnancy</p>
              <p>or childbirth and</p>
              <p>related medical</p>
              <p>conditions), sexual</p>
              <p>orientation, veteran or</p>
              <p>military status, proof of</p>
              <p>eligibility to work</p>
            </td>
            <td>
              <div data-empty="true">No</div>
            </td>
            <td>
              <div data-empty="true">None</div>
            </td>
          </tr>
          <tr>
            <td>
              <p>Internet or other similar</p>
              <p>network activity</p>
            </td>
            <td>
              <p>Browsing history,</p>
              <p>search history,</p>
              <p>information on a</p>
              <p>consumer's interaction</p>
              <p>with the Ghostball</p>
              <p>Services</p>
            </td>
            <td>
              <p>No</p>
            </td>
            <td>
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Geolocation data</p>
            </td>
            <td>
              <p>Location and</p>
              <p>movement data</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>Organizations providing services</p>
              <p>to Ghostball</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Professional or</p>
              <p>employment-related</p>
              <p>information</p>
            </td>
            <td>
              <p>Proof of eligibility to</p>
              <p>work, resumé, other</p>
              <p>professional-related</p>
              <p>information and</p>
              <p>employment-related</p>
              <p>information</p>
            </td>
            <td>
              <p>No</p>
            </td>
            <td>
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Biometric information</p>
            </td>
            <td>
              <p>None</p>
            </td>
            <td>
              <p>No</p>
            </td>
            <td>
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Education Information</p>
            </td>
            <td>
              <p>None</p>
            </td>
            <td>
              <p>No</p>
            </td>
            <td>
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Inferences from the</p>
              <p>foregoing</p>
            </td>
            <td>
              <p>None</p>
            </td>
            <td>
              <p>No</p>
            </td>
            <td>
              <p>None</p>
            </td>
          </tr>
        </tbody>
      </table>
    </TblContainer>
  )
}
