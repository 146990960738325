import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { withTheme, ThemeProps } from 'styled-components'
import { toast } from 'react-toastify'
import axios from 'axios'

import { UseLayerInstance } from '../../blockchain/hooks/useLayerInstance'
import { ComposableNFT } from '../../blockchain/instance'
import { b64ToString, range } from '../../shared/helpers/util'
import { BolderText, CardBodyText, MarkingText } from '../../shared/Typography'
import { ColorPicker } from '../../shared/colorPicker/ColorPicker'
import { NFTAvatarSection } from './components/NFTAvatarSection'
import { TopSection } from './components/TopSection'
import {
  EditArea,
  AtrributesHead,
  BottomSection,
  Container,
  LayerDescription,
  Main,
  InputsContainer,
  ButtonsWrapper,
} from './style'
import {
  APPLYING_CHANGES_TEXT,
  PRIMARY_COLOR_TOOLTIP_TEXT,
  SECONDARY_COLOR_TOOLTIP_TEXT,
  SELECT_PRIMARY_COLOR,
  SELECT_SECONDARY_COLOR,
} from '../../shared/helpers/text'
import { Button } from '../../shared/button'
import PreviewModal from '../../shared/previewModal'
import Modal from '../../shared/modal'
import { SpinnerImg } from '../../shared/helpers/styled'
import spinner from '../../assets/images/spinner.svg'
import { handleError } from '../home/components/mint'
import { nftsPath } from '../../logic/paths'
import { ConnectWallet } from '../app/navbar/ConnectWallet'

export const EditNftLayer: React.FC = withTheme((props: ThemeProps<any>) => {
  const { theme } = props
  const navigate = useNavigate()
  const { id: nftId, layer } = useParams<any>()
  const { library, account } = useWeb3React()
  const [nftSvg, setNftSvg] = useState<any>()
  const [traitValue, setTraitValue] = useState<string>('')
  const [layerColor, setLayerColor] = useState<string>('')
  const [layerColor2, setLayerColor2] = useState<string>('')
  const [initialValues, setInitialValues] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMakingChange, setIsMakingChange] = useState<boolean>(false)
  const [canMakeChange, setCanMakeChange] = useState<boolean>(false)
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [layerAddr, setLayerAddr] = useState('')
  const [showPicker1, setShowPicker1] = useState<boolean>(false)
  const [showPicker2, setShowPicker2] = useState<boolean>(false)
  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(false)
  const [defaultColor1, setDefaultColor1] = useState<string>('')
  const [defaultColor2, setDefaultColor2] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (library) {
      setIsLoading(false)
      init()
    } else {
      setIsLoading(true)
      setCanMakeChange(false)
    }
  }, [library, account])

  const init = async () => {
    try {
      setIsLoading(true)
      const tokenOwner = await ComposableNFT.methods.ownerOf(nftId).call()
      if (tokenOwner !== account) {
        setIsLoading(false)
        setIsOwner(false)
        return
      }
      setIsOwner(true)
      mapLayers()
      let tokenImgJSON
      const tokenB64 = await ComposableNFT.methods.tokenURI(nftId).call()
      const tokenDetails = JSON.parse(b64ToString(tokenB64))
      if (tokenDetails?.image?.includes('ipfs://')) {
        const imageSrc = 'https://ipfs.io/ipfs/' + tokenDetails?.image?.split('://')[1]
        tokenImgJSON = await axios.get(imageSrc)
        setIsEditingDisabled(true)
      } else {
        tokenImgJSON = b64ToString(tokenDetails.image)
        setIsEditingDisabled(false)
      }
      setNftSvg(tokenImgJSON)
    } catch (error) {
      console.error('error---', error)
    }
  }

  const mapLayers = async () => {
    try {
      let layerMapping: any = null
      const layerMappingStr = localStorage.getItem('CONTRACT_LAYERS_MAP')
      if (layerMappingStr) {
        layerMapping = JSON.parse(layerMappingStr)
      }
      // First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
      const numOfLayers = (await ComposableNFT.methods.getLayerContractsLength().call()) || layerMapping?.length
      const allLayersContractsArr = range(0, Number(numOfLayers) - 1)
      const layerArr: any = []

      await Promise.all(
        allLayersContractsArr.map(async (layerIndex: number) => {
          // First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
          const addr =
            (await ComposableNFT.methods.layerContracts(layerIndex).call()) || layerMapping?.[layerIndex]?.address
          const LayerInstance = UseLayerInstance(addr)
          // First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
          const layerName = (await LayerInstance.methods.layerName().call()) || layerMapping?.[layerIndex]?.name

          if (layerName === layer) {
            const options = await LayerInstance.methods.getOptionMetadataByTokenId(nftId).call()
            const selectedOptions = await LayerInstance.methods.selectedOption(nftId).call()
            const trait = JSON.parse(options)
            const layerOptions = await LayerInstance.methods.layerOptions(selectedOptions.optionNumber).call()
            if (layerOptions.defaultColor1Hex) {
              setShowPicker1(true)
              setDefaultColor1(layerOptions.defaultColor1Hex)
            }
            if (layerOptions.defaultColor2Hex) {
              setShowPicker2(true)
              setDefaultColor2(layerOptions.defaultColor2Hex)
            }
            setLayerAddr(addr)
            setTraitValue(trait.value)
            setLayerColor(selectedOptions.color1Hex)
            setLayerColor2(selectedOptions.color2Hex || '')
            setInitialValues([selectedOptions.color1Hex, selectedOptions.color2Hex])
            if (!layerMapping?.length) {
              layerArr.push({
                name: layerName,
                address: addr,
              })
            }
            return true
          }
        })
      )
      if (!layerMapping?.length) {
        localStorage.setItem('CONTRACT_LAYERS_MAP', JSON.stringify(layerArr))
      }
      setIsLoading(false)
    } catch (error) {
      console.error('error in mapLayers', error)
      setIsLoading(false)
    }
  }
  const handleColorChange = (e: any) => {
    setLayerColor(e.target.value)
    if (e.target.value === initialValues[0] && layerColor2 === '') {
      setCanMakeChange(false)
    } else if (e.target.value === initialValues[0] && layerColor2 === initialValues[1]) {
      setCanMakeChange(false)
    } else {
      setCanMakeChange(true)
    }
  }
  const handleColorChange2 = (e: any, clear?: boolean) => {
    if (clear) {
      setLayerColor2('')
      setCanMakeChange(true)
      return
    }
    if (layerColor === initialValues[0] && e.target.value === initialValues[1]) {
      setCanMakeChange(false)
    } else {
      setCanMakeChange(true)
    }
    setLayerColor2(e.target.value)
  }
  const handleResetChanges = () => {
    setLayerColor(defaultColor1)
    setLayerColor2(defaultColor2)
    if (defaultColor1 !== initialValues[0] || defaultColor2 !== initialValues[1]) {
      setCanMakeChange(true)
    } else {
      setCanMakeChange(false)
    }
  }

  const handleApplyChanges = async () => {
    setIsMakingChange(true)
    const LayerInstance = UseLayerInstance(layerAddr)
    const colorChangePrice = await LayerInstance.methods.colorChangePrice().call()

    await LayerInstance.methods
      .setColor(nftId, layerColor, layerColor2)
      .send({ from: account, value: colorChangePrice })
      .on('transactionHash', (hash: any) => {
        console.info('txn hash', hash)
      })
      .on('receipt', async (receipt: any) => {
        setIsMakingChange(false)
        setCanMakeChange(false)
        toast.success('Transaction Successful', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
        })
        setIsLoading(true)
        await mapLayers()
        setIsLoading(false)
      })
      .on('error', (e: any) => {
        setIsMakingChange(false)
        handleError(e)
      })
  }

  const handleShowPreview = () => {
    setShowPreview(true)
    document.body.style.overflow = 'hidden'
  }

  if (!isOwner && !isLoading) {
    navigate(nftsPath)
  }
  if (!account) {
    return <ConnectWallet />
  }

  return (
    <>
      <Container>
        <Main>
          <TopSection />
          <BottomSection>
            <NFTAvatarSection
              handleShowPreview={handleShowPreview}
              layer={layer}
              nftSvg={nftSvg}
              layerColor={layerColor}
              layerColor2={layerColor2}
            />
            {isLoading ? (
              <BolderText fColor={theme.white}>Loading...</BolderText>
            ) : (
              <EditArea>
                <AtrributesHead>
                  <BolderText fSize="26px" fWeight="700" fColor={theme.white}>
                    Ghostball #{nftId}
                  </BolderText>
                  <LayerDescription>
                    <BolderText fontSizeM="26px" fColor={theme.primary}>
                      {layer}: {traitValue}
                    </BolderText>
                    {/* Added small gap between paras to increase readabelity, [DAVE_UPDATE] */}
                    <CardBodyText marginBottom="5px">
                      Some of the Ghostball traits are dynamic. For these traits, we allow you to change the color on
                      the blockchain.
                    </CardBodyText>
                    <CardBodyText marginBottom="5px">
                      For some traits, such as a solid background color, you can only change a primary color. For
                      others, like Football Jersey, you can change primary and secondary colors.
                    </CardBodyText>
                    <CardBodyText marginBottom="5px">
                      For every color change transaction, you'll need to pay gas fees + a color change fee of 0.007 ETH.
                    </CardBodyText>
                  </LayerDescription>
                </AtrributesHead>
                <InputsContainer>
                  <ColorPicker
                    headerText={SELECT_PRIMARY_COLOR}
                    pickerId="1"
                    hidePicker={!showPicker1 || isEditingDisabled}
                    onChange={handleColorChange}
                    value={layerColor}
                    theme={theme}
                    tooltipText={PRIMARY_COLOR_TOOLTIP_TEXT}
                  />
                  <ColorPicker
                    headerText={SELECT_SECONDARY_COLOR}
                    pickerId="2"
                    onChange={handleColorChange2}
                    value={layerColor2}
                    hidePicker={!showPicker2 || isEditingDisabled}
                    theme={theme}
                    tooltipText={SECONDARY_COLOR_TOOLTIP_TEXT}
                  />
                </InputsContainer>
                {!isEditingDisabled && (showPicker1 || showPicker2) && (
                  <ButtonsWrapper>
                    <Button
                      customBorder="none"
                      shadowColor={theme.primary}
                      rippleColor={theme.black}
                      customBgColor={theme.primary}
                      customColor={theme.black}
                      btnType="filledButton"
                      onClick={handleApplyChanges}
                      isDisabled={isLoading || !canMakeChange}
                    >
                      APPLY
                    </Button>
                    <Button
                      customWidth="140px"
                      btnType="borderButton"
                      onClick={handleResetChanges}
                      isDisabled={isLoading || (defaultColor1 === layerColor && defaultColor2 === layerColor2)}
                    >
                      RESET
                    </Button>
                  </ButtonsWrapper>
                )}
              </EditArea>
            )}
          </BottomSection>
        </Main>
      </Container>
      <PreviewModal
        show={showPreview}
        toggleModal={(p: boolean) => {
          setShowPreview(p)
          document.body.style.overflow = 'unset'
        }}
      >
        <NFTAvatarSection
          nftId={nftId}
          layer={layer}
          nftSvg={nftSvg}
          layerColor={layerColor}
          layerColor2={layerColor2}
          isPreview
        />
      </PreviewModal>
      <Modal show={isMakingChange} isPersistent>
        <SpinnerImg src={spinner} alt="transaction-status" isSpinning />
        <MarkingText fColor={theme.white}>{APPLYING_CHANGES_TEXT}</MarkingText>
      </Modal>
    </>
  )
})
function dispatch(arg0: { type: string; payload: any }) {
  throw new Error('Function not implemented.')
}
