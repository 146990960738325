import { withTheme, ThemeProps } from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  BrandingImg,
  DarkTile,
  DetailsCntr,
  FieldMarkingContainer,
  InputContainer,
  Col,
  MintArea,
  MintContainer,
  MintDetailsCntr,
  Row,
  TextCntr,
  WarningCntr,
  TimeBox,
  Tile,
  IconCntr,
  TermsCntr,
  TextWrapper,
  Line,
  TermsBtnContainer,
  LinkIcon,
  CardBodyTextCntr,
  ComingSoonText,
} from './style'
import {
  BolderText,
  CardBodyText,
  CardHeadText,
  MarkingText,
  SmallText,
  TermsBoldText,
  TermsText,
} from '../../../../shared/Typography'
import { Button } from '../../../../shared/button'
import { useIsMobileScreen } from '../../../../shared/hooks/useIsMobileScreen'
import { useCallback, useEffect, useState } from 'react'
import {
  BUY_TEXT,
  CONNECT_WALLET_BTN_TEXT,
  DAYS_TEXT,
  EDIT_TEXT,
  GREETINGS_DESCRIPTION1,
  GREETINGS_DESCRIPTION2,
  GREETINGS_TEXT,
  MINS_TEXT,
  SEC_TEXT,
  HRS_TEXT,
  MINT_ANOTHER_TEXT,
  MINT_TEXT,
  NFTS_MINTED_TEXT,
  NOT_IN_WHITELIST_DESCRIPTION,
  NOT_IN_WHITELIST_TEXT,
  TERMS_MODAL_BODY_TEXT,
  TERMS_MODAL_HEAD_TEXT,
  WHITE_LIST_MINT_ENDS_IN,
  MINTED_ALL_TEXT,
  GB_DISCORD_URL,
  GB_INSTAGRAM_URL,
  GB_YOUTUBE_URL,
  GB_TWITTER_URL,
} from '../../../../shared/helpers/text'
import { useDispatch } from 'react-redux'
import { setConnectWallet, setIsMintingOver } from '../../../../logic/redux/actions'
import { ComposableNFT, ComposableNFTPublic, WhiteList, WhiteListPublic } from '../../../../blockchain/instance'
import { DarkModal } from '../../../../shared/modal/dark-modal'
import { SVGIcon, Tooltip } from '../../../../shared/helpers/styled'
import { ContainerMintTail } from './style'

import brandingImg from '../../../../assets/images/GBNFT-Logo.svg'
import brandingImgSmall from '../../../../assets/images/GB-small.svg'
import warningIcon from '../../../../assets/images/warning-icon.svg'
import tadaIcon from '../../../../assets/images/tada-icon.svg'
import instaIcon from '../../../../assets/images/insta-icon.svg'
import discordIcon from '../../../../assets/images/discord-icon.svg'
import twitterIcon from '../../../../assets/images/twitter-icon.svg'
import youtubeIcon from '../../../../assets/images/youtube-icon.svg'
import twitterIconDark from '../../../../assets/images/twitter-icon-dark.svg'
import discordIconDark from '../../../../assets/images/discord-icon-dark.svg'

import { nftsPath, privacyPolicyPath } from './../../../../logic/paths'
import CustomModal from '../../../../shared/customModal'
import { TOTAL_NFT_SUPPLY, WHITE_LIST_MINT_TIME } from '../../../../shared/helpers/config'
import { clearLocalStorage } from '../../../../shared/helpers/util'
import { TermsBody } from '../../../app/terms/Terms'

const nftCount = 1
let countdownTimer: any = undefined
let seconds = 0
export const Mint: React.FC = withTheme((props: ThemeProps<any>) => {
  const { theme } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { account, active, library, deactivate } = useWeb3React()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mintedAll, setMintedAll] = useState<boolean>(false)

  const [showTermsModal, setShowTermModal] = useState<boolean>(false)
  const [isMinting, setIsMinting] = useState<boolean>(false)
  const [isNotInWhiteList, setIsNotInWhiteList] = useState<boolean>(false)
  const [mintWave, setMintWave] = useState<any>()
  const [mintedCount, setMintedCount] = useState<string>('')
  const [whiteListTime, setWhiteListTime] = useState<number>(0)
  const [whiteListTimeDays, setWhiteListTimeDays] = useState<number>(0)
  const [whiteListTimeHrs, setWhiteListTimeHrs] = useState<number>(0)
  const [whiteListTimeMins, setWhiteListTimeMins] = useState<number>(0)
  const [whiteListTimeSec, setWhiteListTimeSec] = useState<number>(0)
  const [userNFTsCount, setUserNFTsCount] = useState<string>('')
  const [mintBtnText, setMintBtnText] = useState<string>('Loading...')
  const [userMintedNFTs, setUserMintedNFTs] = useState<number>(0)
  const [showMintSuccess, setShowMintSuccess] = useState<boolean>(false)
  const [isBottom, setIsBottom] = useState(false)

  const [isMintedStart, setIsMintedStart] = useState<boolean>(false)

  const handleConnectWallet = () => {
    dispatch(setConnectWallet(true))
    document.body.style.overflow = 'hidden'
  }

  useEffect(() => {
    init()
  }, [library, account])

  const init = async () => {
    setIsLoading(true)
    try {
      //* Updated mint wave to depend on mine rather than on the contract [DAVE_UPDATE]
      // const mintwave = await ComposableNFTPublic.methods.mintWave().call()
      const mintStartDate = await ComposableNFTPublic.methods.mintStartDate().call()
      let mintwave_val = 1
      if (parseInt(mintStartDate) + WHITE_LIST_MINT_TIME < Date.now() / 1000) {
        mintwave_val = 2
      } else {
        mintwave_val = 1
      }
      setMintWave(mintwave_val)

      const totalSupply = TOTAL_NFT_SUPPLY
      const totalMintedNFTs = await ComposableNFTPublic.methods.tokenIds().call()
      if (Number(mintwave_val) === 1) {
        const startDate = await ComposableNFTPublic.methods.mintStartDate().call()
        const timeNow = Math.round(Date.now() / 1000)
        const endTime = Number(startDate) + WHITE_LIST_MINT_TIME - Number(timeNow)
        setMintedCount(`${totalMintedNFTs}/${totalSupply}`)
        setWhiteListTime(endTime)
        seconds = endTime
        if (countdownTimer) {
          clearInterval(countdownTimer)
        }
        countdownTimer = setInterval(() => timer(), 1000)
        if (account) {
          const whitelistedUsers = await WhiteList.methods.whitelistedAddresses(account).call()
          if (!whitelistedUsers) {
            setIsNotInWhiteList(true)
            document.body.style.overflow = 'hidden'
            return
          }
          const userMintedNFTCount = await ComposableNFTPublic.methods.mintsPerAddr(account?.toString()).call()
          setMintBtnText(MINT_TEXT)
          setUserMintedNFTs(Number(userMintedNFTCount))
          if (Number(userMintedNFTCount) > 0 && Number(userMintedNFTCount) < 5) {
            setMintedAll(false)
            setMintBtnText(MINT_ANOTHER_TEXT)
          } else if (Number(userMintedNFTCount) >= 5) {
            setMintedAll(true)
          } else {
            setMintedAll(false)
          }
        }
      } else if (Number(mintwave_val) === 2) {
        if (totalSupply === totalMintedNFTs) {
          // wave 3 ie. all NFTs were minted
          dispatch(setIsMintingOver(true))

          const userNFTCount = await ComposableNFT.methods.balanceOf(account).call()
          if (Number(userNFTCount) > 0) {
            setUserNFTsCount(`${userNFTCount}`)
            setMintBtnText(EDIT_TEXT)
          } else {
            setUserNFTsCount(`No`)
            setMintBtnText(BUY_TEXT)
          }
        } else {
          // * show the nft count event if the mint wave is 2.[DAVE_UPDATE]
          const startDate = await ComposableNFTPublic.methods.mintStartDate().call()
          const timeNow = Math.round(Date.now() / 1000)
          const endTime = Number(startDate) + WHITE_LIST_MINT_TIME - Number(timeNow)

          setMintedCount(`${totalMintedNFTs}/${totalSupply}`)
          setWhiteListTime(endTime)

          seconds = endTime
          if (countdownTimer) {
            clearInterval(countdownTimer)
          }
          countdownTimer = setInterval(() => timer(), 1000)
          if (account) {
            const userMintedNFTCount = await ComposableNFTPublic.methods.mintsPerAddr(account?.toString()).call()
            setMintBtnText(MINT_TEXT)
            setUserMintedNFTs(Number(userMintedNFTCount))
            if (Number(userMintedNFTCount) > 0 && Number(userMintedNFTCount) < 5) {
              setMintedAll(false)
              setMintBtnText(MINT_ANOTHER_TEXT)
            } else if (Number(userMintedNFTCount) >= 5) {
              setMintedAll(true)
            } else {
              setMintedAll(false)
            }
          }
        }
      }
      if (!account) {
        setMintedAll(false)
        setIsNotInWhiteList(false)
        setMintBtnText('Loading...')
        setUserNFTsCount('')
        setUserMintedNFTs(0)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('error...', error)
    }
  }

  function timer() {
    let days: number = Math.floor(seconds / 24 / 60 / 60)
    let hoursLeft: number = Math.floor(seconds - days * 86400)
    let hours: number = Math.floor(hoursLeft / 3600)
    let minutesLeft: number = Math.floor(hoursLeft - hours * 3600)
    let minutes: number = Math.floor(minutesLeft / 60)
    let remainingSeconds: number = seconds % 60
    function pad(n: number) {
      return n < 10 ? '0' + n : n
    }
    setWhiteListTimeDays(days)
    setWhiteListTimeHrs(hours)
    setWhiteListTimeMins(minutes)
    setWhiteListTimeSec(remainingSeconds)
    if (seconds <= 0) {
      clearInterval(countdownTimer)
      setWhiteListTimeDays(0)
      setWhiteListTimeHrs(0)
      setWhiteListTimeMins(0)
      setWhiteListTimeSec(0)
    } else {
      seconds--
    }
  }
  const handleMintBtnClick = () => {
    if (mintBtnText === MINT_TEXT || mintBtnText === MINT_ANOTHER_TEXT) {
      mintNFTs()
    } else if (mintBtnText === EDIT_TEXT) {
      navigate(nftsPath)
    } else if (mintBtnText === BUY_TEXT) {
    }
  }
  const mintNFTs = async () => {
    const termsStr = localStorage.getItem(`terms`) || '{}'
    const termsObj: any = JSON.parse(termsStr)
    if (account && !termsObj[account]) {
      setShowTermModal(true)
      document.body.style.overflow = 'hidden'
      return
    }
    document.body.style.overflow = 'unset'
    try {
      setIsMinting(true)
      // const mintPrice = await ComposableNFT.methods.mintPrice().call()
      const mintPrice = '100000000000000000'

      await ComposableNFT.methods
        .mint()
        .send({ from: account, value: mintPrice })
        .on('transactionHash', (hash: any) => {
          console.info('txn hash', hash)
        })
        .on('receipt', (receipt: any) => {
          setIsMinting(false)
          setShowMintSuccess(true)
          document.body.style.overflow = 'hidden'
          init()
        })
        .on('error', (error: any) => {
          setIsMinting(false)
          console.error('error in minting', error)
        })

      // await ComposableNFT.methods
      //   .startMint()
      //   .send({ from: account })
      //   .on('transactionHash', (hash: any) => {
      //     console.info('txn hash', hash)
      //   })
      //   .on('receipt', (receipt: any) => {
      //     setIsMinting(false)
      //     setShowMintSuccess(true)
      //     document.body.style.overflow = 'hidden'
      //     init()
      //   })
      //   .on('error', (error: any) => {
      //     setIsMinting(false)
      //     console.error('error in minting', error)
      //   })
    } catch (error) {
      setIsMinting(false)
      handleError(error)
    }
  }

  const handleRejectTerms = () => {
    setShowTermModal(false)
    document.body.style.overflow = 'unset'
  }
  const handleAcceptTerms = () => {
    setShowTermModal(false)
    document.body.style.overflow = 'unset'
    const termsStr = localStorage.getItem(`terms`) || '{}'
    const termsObj: any = JSON.parse(termsStr)
    if (account) {
      const newTermsObj = { ...termsObj, [account]: true }
      localStorage.setItem(`terms`, JSON.stringify(newTermsObj))
      mintNFTs()
    }
  }
  const handleCloseNotWhitelistedModal = (isModal: boolean) => {
    setIsNotInWhiteList(isModal)
    disconnect()
  }

  const disconnect = useCallback(() => {
    clearLocalStorage()
    deactivate()
    sessionStorage.clear()
    document.body.style.overflow = 'unset'
  }, [deactivate])

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    setIsBottom(bottom)
  }
  return (
    <>
      <MintContainer id="#mint">
        <Markings top />
        <MintArea>
          <BrandingImg src={brandingImg} alt="bb-ghosball-branfing" />

          {isMintedStart ? (
            <div>
              {active && account ? ( // * show the nft count event if the mint wave is 2.[DAVE_UPDATE]
                <MintDetailsCntr>
                  <InputContainer>
                    {!isLoading && !isMinting && (
                      <Tile>
                        {userNFTsCount
                          ? `${userNFTsCount} NFTs Found`
                          : Number(mintWave) === 1
                          ? `Minted: ${userMintedNFTs}/5`
                          : `Minted: ${userMintedNFTs}/5`}
                      </Tile>
                    )}

                    <Tooltip left="170px" customWidth="18em" isDisabled={!mintedAll} data-tooltip={MINTED_ALL_TEXT}>
                      <Button
                        customWidth="170px"
                        isDisabled={isLoading || isMinting || mintedAll}
                        onClick={handleMintBtnClick}
                        shadowColor={theme.black}
                        btnType="filledButton"
                      >
                        {isMinting ? 'Minting..' : mintBtnText}
                      </Button>
                    </Tooltip>
                  </InputContainer>
                  {/* show the total nfts minted even if the mint wave is 2 [DAVE_UPDATE] */}
                  <DetailsCntr>
                    {whiteListTime > 0 ? (
                      <Col>
                        <SmallText fSize="18px" fLineHeight="20px" fWeight="700">
                          {WHITE_LIST_MINT_ENDS_IN}
                        </SmallText>
                        <Row>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeDays}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {DAYS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeHrs}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {HRS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeMins}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {MINS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeSec}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {SEC_TEXT}
                            </CardBodyText>
                          </TimeBox>
                        </Row>
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col>
                      <SmallText fSize="18px" fLineHeight="20px" fWeight="700">
                        {NFTS_MINTED_TEXT}
                      </SmallText>
                      <DarkTile>
                        <BolderText fColor={theme.primary}>{mintedCount}</BolderText>
                      </DarkTile>
                    </Col>
                  </DetailsCntr>
                </MintDetailsCntr>
              ) : (
                <MintDetailsCntr>
                  <Button customWidth="230px" onClick={handleConnectWallet} btnType="ConnectWalletButton">
                    {CONNECT_WALLET_BTN_TEXT}
                  </Button>
                  <DetailsCntr>
                    {/* show the timer only if the whitelistTime is +ve if not only show the total nft minted count, [DAVE_UPDATE] */}
                    {whiteListTime > 0 ? (
                      <Col>
                        <SmallText fSize="18px" fLineHeight="20px" fWeight="700">
                          {WHITE_LIST_MINT_ENDS_IN}
                        </SmallText>
                        <Row>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeDays}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {DAYS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeHrs}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {HRS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeMins}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {MINS_TEXT}
                            </CardBodyText>
                          </TimeBox>
                          <TimeBox>
                            <CardHeadText fSize="40px" fColor={theme.primary}>
                              {whiteListTimeSec}
                            </CardHeadText>
                            <CardBodyText fSize="14px" fLineHeight="16px">
                              {SEC_TEXT}
                            </CardBodyText>
                          </TimeBox>
                        </Row>
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col>
                      <SmallText fSize="18px" fLineHeight="20px" fWeight="700">
                        {NFTS_MINTED_TEXT}
                      </SmallText>
                      <DarkTile>
                        <BolderText fColor={theme.primary}>{mintedCount}</BolderText>
                      </DarkTile>
                    </Col>
                  </DetailsCntr>
                </MintDetailsCntr>
              )}
            </div>
          ) : (
            <ComingSoonText>Coming Soon!</ComingSoonText>
          )}
        </MintArea>
        <Markings />
        <ContainerMintTail>
          <LinkIcon href={GB_TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={twitterIcon} alt="twitter-icon" />
          </LinkIcon>
          <LinkIcon href={GB_DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={discordIcon} alt="discord-icon" />
          </LinkIcon>
          <LinkIcon href={GB_YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={youtubeIcon} alt="youtube-icon" />
          </LinkIcon>
          <LinkIcon href={GB_INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <SVGIcon height="45px" width="45px" src={instaIcon} alt="insta-icon" />
          </LinkIcon>
        </ContainerMintTail>
      </MintContainer>
      <DarkModal
        toggleModal={(p: boolean) => {
          handleCloseNotWhitelistedModal(p)
        }}
        modalColor={theme.black}
        show={isNotInWhiteList}
      >
        <WarningCntr>
          <SVGIcon height="48px" width="48px" src={warningIcon} />
          <TextCntr>
            <MarkingText fLineHeight="23px" fSize="20px" fWeight="700" fColor={theme.white}>
              {NOT_IN_WHITELIST_TEXT}
            </MarkingText>
            <SmallText fLineHeight="16px" fSize="14px" fColor={theme.white6}>
              {NOT_IN_WHITELIST_DESCRIPTION}
            </SmallText>
          </TextCntr>
        </WarningCntr>
      </DarkModal>
      <DarkModal
        toggleModal={(p: boolean) => {
          setShowMintSuccess(p)
          document.body.style.overflow = 'unset'
        }}
        modalColor={theme.black}
        show={showMintSuccess}
        minHeight="360px"
      >
        <WarningCntr>
          <SVGIcon height="60px" width="60px" src={tadaIcon} />
          <TextCntr>
            <MarkingText fLineHeight="23px" fSize="20px" fWeight="700" fColor={theme.white}>
              {GREETINGS_TEXT}
            </MarkingText>
            <SmallText fLineHeight="16px" fSize="14px" fColor={theme.white8}>
              {GREETINGS_DESCRIPTION1}
            </SmallText>
            <SmallText fLineHeight="16px" fSize="14px" fColor={theme.white6}>
              {GREETINGS_DESCRIPTION2}
            </SmallText>
          </TextCntr>
          <IconCntr>
            <LinkIcon href={GB_TWITTER_URL} target="_blank" rel="noopener noreferrer">
              <SVGIcon height="45px" width="45px" src={twitterIconDark} alt="twitter-icon" />
            </LinkIcon>
            <LinkIcon href={GB_DISCORD_URL} target="_blank" rel="noopener noreferrer">
              <SVGIcon height="45px" width="45px" src={discordIconDark} alt="discord-icon" />
            </LinkIcon>
          </IconCntr>
        </WarningCntr>
      </DarkModal>
      <CustomModal
        show={showTermsModal}
        toggleModal={(p: boolean) => {
          setShowTermModal(p)
          if (!p) {
            const element: any = document.getElementById('#topOfTerms')
            element.scrollTo(0, 0)
          }
          document.body.style.overflow = 'unset'
        }}
        isTerms
        hideHead
      >
        <TermsCntr>
          <TextWrapper gap="20px">
            <SVGIcon height="22px" width="315px" widthM="230px" src={brandingImgSmall} />
            <CardBodyText fSize="18px" fWeight="700" fLineHeight="20px">
              {TERMS_MODAL_HEAD_TEXT}
            </CardBodyText>
          </TextWrapper>
          <TextWrapper>
            <CardBodyTextCntr id="#topOfTerms" onScroll={handleScroll}>
              <TermsBody />
            </CardBodyTextCntr>
          </TextWrapper>
          <Line />
          <TermsBtnContainer>
            <Button
              customBorder={'none'}
              shadowColor={theme.white}
              rippleColor={theme.black}
              customBgColor={theme.fadedWhite}
              customColor={theme.white}
              btnType="filledButton"
              customWidth="155px"
              fWeight="500"
              fSize="14px"
              onClick={handleRejectTerms}
            >
              REJECT
            </Button>
            <Button
              customBorder="none"
              shadowColor={theme.primary}
              rippleColor={'#6b9702'}
              customBgColor={theme.primary}
              customColor={theme.black}
              btnType="filledButton"
              customWidth="155px"
              fWeight="500"
              fSize="14px"
              onClick={handleAcceptTerms}
              isDisabled={!isBottom}
            >
              ACCEPT & MINT
            </Button>
          </TermsBtnContainer>
        </TermsCntr>
      </CustomModal>
    </>
  )
})

interface IMarkingsProps {
  top?: boolean
}
export const Markings = (props: IMarkingsProps) => {
  const [markingArray, setMarkingArray] = useState([10, 20, 30, 40, 50, 40, 30, 20, 10])
  const isMobileScreen = useIsMobileScreen()
  useEffect(() => {
    if (isMobileScreen) {
      setMarkingArray([40, 50, 40])
    } else {
      setMarkingArray([10, 20, 30, 40, 50, 40, 30, 20, 10])
    }
  }, [isMobileScreen])
  return (
    <FieldMarkingContainer {...props}>
      {markingArray.map((el: number, index: number) => {
        return <MarkingText key={index}>{el}</MarkingText>
      })}
    </FieldMarkingContainer>
  )
}

export const handleError = (error: any) => {
  console.error('error in minting', error.code)
  if (error.code === 4001 || error.code === -32000) {
    toast.warn('Transaction Rejected', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    })
  } else {
    toast.error('Transaction Failed', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    })
  }
}
