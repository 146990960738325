import { useNavigate, useParams } from 'react-router-dom'
import { ThemeProps, withTheme } from 'styled-components'
import {
  Bottom,
  Container,
  Main,
  AtrributesArea,
  AtrributesList,
  AttributeBox,
  GreenTile,
  NFTAtrributesHead,
  AnimatedAttributeBox,
} from './style'
import axios from 'axios'

import { useEffect, useState } from 'react'
import { ComposableNFT } from '../../blockchain/instance'
import { useWeb3React } from '@web3-react/core'
import { WalletText, CardBodyText, BolderText } from '../../shared/Typography'

import { b64ToString, range } from '../../shared/helpers/util'
import { TopSection } from './components/TopSection'
import { NFTAvatarSection } from './components/NFTAvatarSection'
import PreviewModal from '../../shared/previewModal'
import { Button } from '../../shared/button'
import { nftsPath } from './../../logic/paths'
import { ConnectWallet } from '../app/navbar/ConnectWallet'
import { UseLayerInstance } from '../../blockchain/hooks/useLayerInstance'
import { useDispatch, useSelector } from 'react-redux'
import { setLayerContracts } from '../../logic/redux/actions'

export const NFT: React.FC = withTheme((props: ThemeProps<any>) => {
  const { theme } = props
  const dispatch = useDispatch()
  const { id: nftId } = useParams<any>()
  const navigate = useNavigate()
  const { account, library } = useWeb3React()

  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [nftSvg, setNftSvg] = useState<any>()
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const [nftAttributes, setNftAttributes] = useState<any>([])
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const globalSelector = useSelector((state: any) => state)
  const { layerContracts } = globalSelector.navbar

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (library) {
      setIsLoading(false)
      init()
    } else {
      setIsLoading(true)
    }
  }, [library, account])

  const init = async () => {
    try {
      setIsLoading(true)

      const tokenOwner = await ComposableNFT.methods.ownerOf(nftId).call()
      if (tokenOwner !== account) {
        setIsLoading(false)
        setIsOwner(false)
        return
      }
      setIsOwner(true)
      let layerMapping: any = null
      const layerMappingStr = localStorage.getItem('CONTRACT_LAYERS_MAP')
      if (layerMappingStr) {
        layerMapping = JSON.parse(layerMappingStr)
      }
      // const allLayersContracts = await ComposableNFT.methods.getLayerContractsLength().call()
      //* First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
      const numOfLayers = (await ComposableNFT.methods.getLayerContractsLength().call()) || layerMapping?.length
      const allLayersContractsArr = range(0, Number(numOfLayers) - 1)
      const obj: any = {}
      const layerArr: any = []
      await Promise.all(
        allLayersContractsArr.map(async (layerIndex: number) => {
          //* First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
          const addr =
            (await ComposableNFT.methods.layerContracts(layerIndex).call()) || layerMapping?.[layerIndex]?.address
          const LayerInstance = UseLayerInstance(addr)
          //* First check the contrat the use the local data if the contract data is not available [DAVE_UPDATE]
          const layerName = (await LayerInstance.methods.layerName().call()) || layerMapping?.[layerIndex]?.name
          const selectedOption = await LayerInstance.methods.selectedOption(nftId).call()
          const layerOptions = await LayerInstance.methods.layerOptions(selectedOption.optionNumber).call()
          obj[layerName] = {
            address: addr,
            color1: layerOptions.defaultColor1Hex,
            color2: layerOptions.defaultColor2Hex,
          }
          layerArr.push({
            name: layerName,
            address: addr,
          })
        })
      )
      if (!layerMapping?.length) {
        localStorage.setItem('CONTRACT_LAYERS_MAP', JSON.stringify(layerArr))
      }

      dispatch(setLayerContracts(obj))
      let tokenImgJSON
      const tokenB64 = await ComposableNFT.methods.tokenURI(nftId).call()
      const tokenDetails = JSON.parse(b64ToString(tokenB64))
      if (tokenDetails?.image?.includes('ipfs://')) {
        const imageSrc = 'https://ipfs.io/ipfs/' + tokenDetails?.image?.split('://')[1]
        tokenImgJSON = await axios.get(imageSrc)
        setIsEditingDisabled(true)
      } else {
        tokenImgJSON = b64ToString(tokenDetails.image)
        setIsEditingDisabled(false)
      }

      setNftAttributes(tokenDetails.attributes)
      setNftSvg(tokenImgJSON)
      setIsLoading(false)
    } catch (error) {
      console.error('error---', error)
      setIsLoading(false)
    }
  }

  const editLayer = (layer: IEditLayerProps) => {
    navigate(`/nfts/${nftId}/${layer.trait_type.replace('Layer', '')}`)
  }

  const handleShowPreview = () => {
    setShowPreview(true)
    document.body.style.overflow = 'hidden'
  }

  if (!isOwner && !isLoading) {
    navigate(nftsPath)
  }
  if (!account) {
    return <ConnectWallet />
  }

  return (
    <>
      <Container>
        <Main>
          <TopSection />
          <Bottom>
            <NFTAvatarSection handleShowPreview={handleShowPreview} nftSvg={nftSvg} />
            <AtrributesArea>
              <NFTAtrributesHead>
                <BolderText fontSizeM="26px" fColor={theme.white}>
                  Ghostball #{nftId}
                </BolderText>
                <GreenTile>{nftAttributes.length} Attributes</GreenTile>
              </NFTAtrributesHead>
              <AtrributesList isLoading={isLoading}>
                {isLoading ? (
                  <BolderText fColor={theme.white}>Loading...</BolderText>
                ) : (
                  nftAttributes &&
                  nftAttributes.map((el: any, idx: any) => {
                    return (layerContracts?.[el.trait_type]?.color1 || layerContracts?.[el.trait_type]?.color2) &&
                      !isEditingDisabled ? (
                      <AnimatedAttributeBox key={idx}>
                        <WalletText
                          className="attributeText"
                          fontSizeM="18px"
                          wSpace="nowrap"
                          fWeight="700"
                          fColor={theme.primary}
                        >
                          {el.trait_type.replace('Layer', '')}
                        </WalletText>
                        {/* If the attribute name is too large, it was getting clipped before, [DAVE_UPDATE] */}
                        <CardBodyText className="attributeText" wSpace="break-spaces">
                          {el.value}
                        </CardBodyText>
                        <Button
                          customWidth="120px"
                          customColor={theme.black}
                          customBgColor={theme.primary}
                          className={'attributeBtn'}
                          btnType="tileButton"
                          onClick={() => editLayer(el)}
                        >
                          Edit
                        </Button>
                      </AnimatedAttributeBox>
                    ) : (
                      <AttributeBox key={idx}>
                        <WalletText
                          className="attributeText"
                          fontSizeM="18px"
                          wSpace="nowrap"
                          fWeight="700"
                          fColor={theme.primary}
                        >
                          {el.trait_type.replace('Layer', '')}
                        </WalletText>
                        {/* If the attribute name is too large, it was getting clipped before, [DAVE_UPDATE] */}
                        <CardBodyText className="attributeText" wSpace="break-spaces">
                          {el.value}
                        </CardBodyText>
                      </AttributeBox>
                    )
                  })
                )}
              </AtrributesList>
            </AtrributesArea>
          </Bottom>
        </Main>
      </Container>
      <PreviewModal
        show={showPreview}
        toggleModal={(p: boolean) => {
          setShowPreview(p)
          document.body.style.overflow = 'unset'
        }}
      >
        <NFTAvatarSection nftId={nftId} nftSvg={nftSvg} isPreview />
      </PreviewModal>
    </>
  )
})
interface IEditLayerProps {
  trait_type: string
  value: string
}
